import React, { Suspense, lazy } from 'react'
import { ApolloProvider } from '@apollo/client'
import ToggleColorMode from './Contexts/ColorModeContext'
import { client } from './Contexts/GraphqlContext'
import LoadingSuspense from './Components/Loading/LoadingSuspense'
import ToggleLanguage from './Contexts/DirectionContext'
import { Route, Routes } from 'react-router-dom'
import Page404 from './Components/404'
import ResetPassword from './Pages/Auth/ResetPassword'
import { AppRoutes } from './Constants/Routes'
import { AuthProvider } from './Contexts/AuthContext'

const Main = () => {
  const App = lazy(() => import('./App'));
  const Login = lazy(() => import('./Pages/Auth/Login'));
  const RequireAuth = lazy(() => import("./RequireAuth"));

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <Suspense fallback={<LoadingSuspense />}>
          <ToggleLanguage>
            <ToggleColorMode>
              <Routes>
                <Route element={<RequireAuth />}>
                  <Route path="/*" element={<App />} />
                </Route>
                <Route path={AppRoutes.login} element={<Login />} />
                <Route path={AppRoutes.resetPassword} element={<ResetPassword />} />
                <Route path='*' element={<Page404 />}/>
              </Routes>
            </ToggleColorMode>
          </ToggleLanguage>
        </Suspense>
      </ApolloProvider>
    </AuthProvider>
  )
}

export default Main
