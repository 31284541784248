import React from 'react'
import { Box, Button, Container, InputAdornment, Typography } from '@mui/material'
import CustomInput from '../../Components/Input/CustomInput';
import { IoChevronForwardOutline, IoMailOutline } from 'react-icons/io5';

const ResetPassword = () => {
  return (
    <Container maxWidth='sm' sx={{padding: "2% 0"}}>
        <Typography variant='h6'>Reset Password</Typography>
        <Typography>A password reset link will be sent to the email entered below</Typography>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: 2, padding: '3% 0'}}>
        <CustomInput placeholder='E-mail' id="email" label="Email Address"
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <IoMailOutline />
                    </InputAdornment>
                )
            }}
        />
        </Box>
         <Box sx={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "3%"}}>
            <Button variant='contained' sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: 2, py: 1.2}}>Send Link<IoChevronForwardOutline style={{color: "white", fontWeight: "bolder", fontSize: '1.1rem'}} /></Button>
        </Box>
    </Container>
  )
}

export default ResetPassword
