import { initialUserAddressError } from "../Constants/Addresses.constants";
import { Types } from "../Constants/Types";
import AddressType from "../Interfaces/address.interface";

const AddressesReducer = (state: AddressType['status'] = { 
        address: undefined,
        addresses: [],
        laddresses: false,
        laddress: false,
        error: initialUserAddressError
    },
    action: AddressType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_ADDRESSES:
            return {...state, addresses: action.payload};

        case Types.FETCH_ADDRESS:
            return {...state, address: action.payload};

        case Types.START_ADDRESSES:
            return {...state, laddresses: true};

        case Types.END_ADDRESSES:
            return {...state, laddresses: false};

        case Types.ERROR_PROMOCODE:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_PROMOCODE:
            return {...state, error: initialUserAddressError}

        default: 
            return state
    }
}

export default AddressesReducer;