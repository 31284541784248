import { Types } from '../Constants/Types';
import { Dispatch } from 'redux';
import AuthTypes from '../Interfaces/auth-interface';
import { client } from '../Contexts/GraphqlContext';
import { NavigateFunction } from 'react-router-dom';
import { info, infoDriver, loginAdmin, logoutGql, mutationLoginDriver, mutationLogoutDriver, refresh, refreshDriver } from '../GraphQL/auth.graphql';
import { AppRoutes } from '../Constants/Routes';

export const login = (loginAdminInput: AuthTypes["loginInput"], navigate: NavigateFunction, func: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: Types.S_LOADING });
      const { data } = await client.mutate({ mutation: loginAdmin, variables: { loginAdminInput },});
      dispatch({ type: Types.LOGIN, payload: data.loginAdmin });
      dispatch({ type: Types.E_LOADING });
      navigate("/", { replace: true });
      func();
    } catch ({message}: any) {
      dispatch({ type: Types.AUTH_ERROR, payload: message });
      dispatch({ type: Types.E_LOADING });
    }
};

export const getInfo = (navigate: NavigateFunction) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Types.START_INFO_LOADING });
    const { data } = await client.query({ query: info });
    dispatch({ type: Types.INFO, payload: data.infoAdmin });
    dispatch({ type: Types.END_INFO_LOADING });
  } catch ({message} : any) {
    try {
      const { data: success } = await client.query({ query: refresh });
      if(success !== "Success") {
        dispatch({ type: Types.LOGOUT, payload: null });
        navigate(AppRoutes.login, { replace: true });
      } else {
        await client.query({ query: refresh });
        const { data } = await client.query({ query: info });
        dispatch({ type: Types.INFO, payload: data.infoAdmin });
        dispatch({ type: Types.END_INFO_LOADING });
        setInterval(async ()=> {
         await client.query({ query: refresh });
        }, 1000*60*60*12)
      }
    } catch (error) {
      dispatch({ type: Types.LOGOUT, payload: null });
      navigate(AppRoutes.login, { replace: true });
    }
    //console.log(message);
  }
};

export const logout = (navigate: NavigateFunction) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Types.LOGOUT, payload: null });
    dispatch({ type: Types.START_INFO_LOADING });
    await client.mutate({ mutation: logoutGql });
    dispatch({ type: Types.END_INFO_LOADING });
    navigate(AppRoutes.login, { replace: true });
  } catch (error) {
    dispatch({ type: Types.END_INFO_LOADING });
    dispatch({ type: Types.LOGOUT, payload: null });
    console.log(error);
  }
};

// =================================
//* Drivers
// =================================

export const loginDriver = (loginDriverInput: AuthTypes["loginInput"], navigate: NavigateFunction, func: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Types.S_LOADING });
    const { data } = await client.mutate({ mutation: mutationLoginDriver, variables: { loginDriverInput },});
    dispatch({ type: Types.LOGIN, payload: data.loginDriver });
    dispatch({ type: Types.E_LOADING });
    navigate("/", { replace: true });
    func();
  } catch ({message}: any) {
    dispatch({ type: Types.AUTH_ERROR, payload: message });
    dispatch({ type: Types.E_LOADING });
  }
};

export const getInfoDriver = (navigate: NavigateFunction) => async (dispatch: Dispatch) => {
try {
  dispatch({ type: Types.START_INFO_LOADING });
  const { data } = await client.query({ query: infoDriver });
  dispatch({ type: Types.INFO, payload: data.infoDriver });
  dispatch({ type: Types.END_INFO_LOADING });
} catch ({message} : any) {
  try {
    const { data: success } = await client.query({ query: refreshDriver });
    if(success !== "Success") {
      dispatch({ type: Types.LOGOUT, payload: null });
      navigate(AppRoutes.login, { replace: true });
    } else {
      await client.query({ query: refreshDriver });
      const { data } = await client.query({ query: infoDriver });
      dispatch({ type: Types.INFO, payload: data.infoDriver });
      dispatch({ type: Types.END_INFO_LOADING });
      setInterval(async ()=> {
       await client.query({ query: refreshDriver });
      }, 1000*60*60*12)
    }
  } catch (error) {
    dispatch({ type: Types.LOGOUT, payload: null });
    navigate(AppRoutes.login, { replace: true });
  }
  //console.log(message);
}
};

export const logoutDriver = (navigate: NavigateFunction) => async (dispatch: Dispatch) => {
try {
  dispatch({ type: Types.LOGOUT, payload: null });
  dispatch({ type: Types.START_INFO_LOADING });
  await client.mutate({ mutation: mutationLogoutDriver });
  dispatch({ type: Types.END_INFO_LOADING });
  navigate(AppRoutes.login, { replace: true });
} catch (error) {
  dispatch({ type: Types.END_INFO_LOADING });
  dispatch({ type: Types.LOGOUT, payload: null });
  console.log(error);
}
};