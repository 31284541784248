import React, { useState, useMemo, createContext, useContext } from "react";

import ContextTypes from "../Interfaces/context.interface";
import { LangPage } from "../Hooks/GeneralFunc";
import i18next from "i18next";

export const useDir = () => useContext(DirectionContext)

export const DirectionContext = createContext({ 
    changeDir: (langauge: string) => {},
    dir: 'ltr',
    Globals : {} as ContextTypes['globals'],
    AR: false,
    language: String()
});

export default function ToggleLanguage({children}: ContextTypes['childrenProps']) {
    const [lang, setLang] = useState(localStorage.getItem('i18nextLng') || 'en');
    const [dir, setDir] = useState<any>('ltr');
    const [AR, setAR] = useState<any>((lang === 'ar' || lang === 'kr') ? true : false);
    LangPage((lang === 'ar' || lang === 'kr') ? 'rtl' : 'ltr');

    let Globals = useMemo(()=> {
        const direction = document.dir;
        const Left = direction === 'rtl' ? 'Left' : 'Right';
        const Right = direction === 'rtl' ? 'Right' : 'Left';
        const left = direction === 'rtl' ? 'left' : 'right';
        const right = direction === 'rtl' ? 'right' : 'left';
        const start = direction === 'rtl' ? 'start' : 'end';
        const end = direction === 'rtl' ? 'end' : 'start';
        const l = direction === 'rtl' ? 'l' : 'r';
        const r = direction === 'rtl' ? 'r' : 'l'; 
        return {
            Left: Left,
            Right: Right,
            left: left,
            right: right,
            start: start,
            end: end,
            direction: direction,
            marginLeft: `margin${Left}`,
            marginRight: `margin${Right}`,
            paddingLeft: `padding${Left}`,
            paddingRight: `padding${Right}`,
            borderLeft: `border${Left}`,
            borderRight: `border${Right}`,
            mr: `m${r}`,
            ml: `m${l}`,
            pr: `p${r}`,
            pl: `p${l}`
        };
    }, [document.dir]);

    const languageMode = useMemo(
      () => ({
        changeDir: (language: string) => {
            if(language === "ar" || language === "kr") {
                setLang(language);
                i18next.changeLanguage(language);
                setDir((prev : string) => {
                    if (prev === 'rtl'){
                        LangPage('ltr');
                        Globals.direction = 'ltr';
                        setAR(false);
                        return 'ltr'
                    } else {
                        LangPage('rtl');
                        Globals.direction = 'rtl';
                        setAR(true);
                        return 'rtl'
                    }
                });
            } else {
                setLang(language);
                i18next.changeLanguage(language);
            }
        },
      }),
      [],
    );

    return (
        <DirectionContext.Provider value={{...languageMode, Globals, dir, AR, language: lang}}>
            {children}
        </DirectionContext.Provider>
    )
}
