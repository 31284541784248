import { initialDiscountErrors } from "../Constants/Offers.constants";
import { Types } from "../Constants/Types";
import DiscountType from "../Interfaces/discounts.interface";

const DiscountsReducer = (state: DiscountType['status'] = { 
        discount: undefined,
        discounts: [],
        ldiscounts: false,
        ldiscount: false,
        pages: 0,
        error: initialDiscountErrors
    },
    action: DiscountType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_DISCOUNTS:
            return {...state, discounts: action.payload, pages: action.pages};

        case Types.FETCH_DISCOUNT:
            return {...state, discount: action.payload};

        case Types.CREATE_DISCOUNT:
            return {...state, discounts: [action.payload, ...state.discounts]};

        case Types.UPDATE_DISCOUNT: 
            return {...state, discounts: state.discounts.map(discount => discount._id === action.payload.id ? action.payload : discount)};
        
        case Types.STATE_DISCOUNT: 
            return {...state, discounts: state.discounts.map(discount => discount._id === action.payload.id ? {...discount, ...action.payload} : discount), discount: {...state.discount, state: action.payload.state}};

        case Types.DELETE_DISCOUNT: 
            return {...state, discounts: state.discounts.filter(discount => discount._id !== action.payload)}

        case Types.START_DISCOUNTS:
            return {...state, ldiscounts: true};

        case Types.END_DISCOUNTS:
            return {...state, ldiscounts: false};

        case Types.ERROR_DISCOUNT:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_DISCOUNT:
            return {...state, error: initialDiscountErrors}

        default: 
            return state
    }
}

export default DiscountsReducer;