import { combineReducers } from 'redux';
import Auth from '../Reducers/Auth.reducer';
import Notifications from '../Reducers/Notifications.reducer';
import Reports from '../Reducers/Reports.reducer';
import Settings from '../Reducers/Settings.reducer';
import Orders from '../Reducers/Scraping-orders.reducer';
import Advertisements from '../Reducers/Advertisements.reducer';
import MainSections from '../Reducers/Main-sections.reducer';
import Customers from '../Reducers/Customers.reducer';
import Groups from '../Reducers/Groups.reducer';
import PromoCodes from '../Reducers/Promo-codes.reducer';
import ScrapingItems from '../Reducers/Scraping-items.reducer';
import MainSectionCategories from '../Reducers/Main-section-categories.reducer';
import Discounts from '../Reducers/Discounts.reducer';
import Transactions from '../Reducers/Transactions.reducer';
import Admins from '../Reducers/Admins.reducer';
import PaymentMethods from '../Reducers/Payment-methods.reducer';
import Drivers from '../Reducers/Drivers.reducer';
import Addresses from '../Reducers/Addresses.reducer';
import Teams from '../Reducers/Teams.reducer';
import CartOffers from '../Reducers/Cart-offers.reducer';
import PaymentMethodDiscounts from '../Reducers/Payment-method-discounts.reducer';
import OrderNotes from '../Reducers/Order-notes.reducer';
import OrderDeliveryNotes from '../Reducers/Order-delivery-notes.reducer';
import OrderHistories from '../Reducers/Order-histories.reducer';
import Home from '../Reducers/Home.reducer';
import Products from '../Reducers/Products.reducer';
import Outfits from '../Reducers/Outfits.reducer';

const selector = combineReducers({
    Auth,
    Home,
    Admins,
    Drivers,
    Notifications,
    Addresses,
    Orders,
    OrderNotes,
    OrderDeliveryNotes,
    OrderHistories,
    Advertisements,
    MainSections,
    MainSectionCategories,
    Customers,
    Groups,
    PromoCodes,
    PaymentMethodDiscounts,
    Discounts,
    ScrapingItems,
    Products,
    Outfits,
    Reports,
    Transactions,
    Settings,
    PaymentMethods,
    CartOffers,
    Teams
});
export default selector;

export type SelectorState = ReturnType<typeof selector>