
export const TitlePage = (title :string) => {
  return document.title = `${title} | Akkooo`;
}

export const LangPage = (lang :string) => {
  return document.dir = lang;
}

export const ContentPageColor = (color :string) => {
    document.body.style.backgroundColor = color
    const content = document.getElementsByName("theme-color")[0]
    return content.setAttribute("content", color)
}

export const handleClickLink = (url: string) => {window.open(url)};