import AdminType from "../Interfaces/admin-interface";

export type objects = 
    "Home" | "Setting" | 
    "Wallet" | "ScrapingOrder" | 
    "Advertisement" | "User" | 
    "MainSectionCategory" | "MainSection" | 
    "Group" | "Transaction" | 
    "Report" | "ScrapingItem" | 
    "Notification" | "PromoCode" | 
    "Discount" | "CartOffer" | "PaymentMethodDiscount" |
    "Driver" | "Admin" | "Team" | "PaymentMethod" |
    "all" | "Self";

export const initialPermissions: AdminType['initialPermission'][] = [
    {object: "Home", abilities: []},
    {object: "ScrapingOrder", abilities: []},
    {object: "Advertisement", abilities: []},
    {object: "User", abilities: []},
    {object: "Category", abilities: []},
    {object: "Restaurant", abilities: []},
    {object: "Meal", abilities: []},
    {object: "Transaction", abilities: []},
    {object: "Report", abilities: []},
    {object: "Feedback", abilities: []},
    {object: "Notification", abilities: []},
    {object: "PromoCode", abilities: []},
    {object: "Driver", abilities: []},
    {object: "Admin", abilities: []},
    {object: "Wallet", abilities: []},
    {object: "Setting", abilities: []},
];