import { Types } from "../Constants/Types";
import NotificationType from "../Interfaces/notification-interface";


const NotificationsReducer = (state: NotificationType['status'] = {
        notification: undefined,
        notifications: [],
        managementNotifications: [],
        lnotifications: false,
        lmanagementnotifications: false,
        pages: 0,
        managementPages: 0,
        error: ''
    },
    action: NotificationType['action']) => {

    switch (action.type) {

        case Types.FETCH_NOTIFICATIONS:
            return {...state, notifications: action.payload, pages: action.pages};

        case Types.FETCH_NOTIFICATION:
            return {...state, notification: action.payload};

        case Types.FETCH_MANAGEMENT_NOTIFICATIONS:
            if(action.payload.page === 0){
                return {...state, managementNotifications: action.payload.data, managementPages: action.pages};
            } else {
                return {...state, managementNotifications: [...state.managementNotifications, ...action.payload.data], managementPages: action.pages};
            }

        case Types.CREATE_NOTIFICATION:
            return {...state, notifications: [action.payload, ...state.notifications]};

        case Types.DELETE_NOTIFICATION: 
            return {...state, notifications: state.notifications.filter(noti => noti._id !== action.payload)}

        case Types.FETCH_MANAGEMENT_NOTIFICATION:
            return {...state, managementNotifications: [action.payload, ...state.managementNotifications]};

        case Types.START_NOTIFICATIONS:
            return {...state, lnotifications: true};

        case Types.END_NOTIFICATIONS:
            return {...state, lnotifications: false};

        case Types.START_MANAGEMENT_NOTIFICATIONS:
            return {...state, lmanagementnotifications: true};

        case Types.END_MANAGEMENT_NOTIFICATIONS:
            return {...state, lmanagementnotifications: false};

        case Types.MAIN_ERROR:
            return {...state, error: action.payload};

        default: 
            return state
    }
}

export default NotificationsReducer;