
export const initialErrors = { 
    firstName: String() || null,
    lastName: String() || null,
    phoneNumber: String() || null,
    password: String() || null,
    permissions: String() || null,
}
  
  export const initialDriver = {
    firstName: String(),
    lastName: String(),
    phoneNumber: String(),
    password: String(),
  };
  
  export type initialDriverType = typeof initialDriver;
  export type initialErrorsType = typeof initialErrors;
  
  export const driversTableHead = [
      {
        id: 'name',
        numeric: true,
        label: 'Name',
      },
      {
        id: 'phoneNumber',
        numeric: true,
        label: 'Phone Number',
      },
      {
        id: 'location',
        numeric: true,
        label: 'Location',
      },
      {
        id: 'state',
        numeric: true,
        label: 'Status',
      },
      {
        id: 'createdAt',
        numeric: true,
        label: 'CreatedAt',
      },
      {
        id: '',
        numeric: false,
        label: '',
      },
    ];