
export const initialCartOffer = {
    title: {
        main: "",
        en: String() || null,
        kr: String() || null
    },
    type: String(),
    discountTarget: String(),
    discount: Number(),
    limit: Number(),
  }
  export type initialCartOfferType = typeof initialCartOffer;

export const initialCartOfferErrors = {
    title: String() || null,
    discount: String() || null,
    discountTarget: String() || null,
    type: String() || null,
    limit: String() || null,
}
export type initialCartOfferErrorsType = typeof initialCartOfferErrors;

  export const cartOfferTypes = [
    {label: "None", value: null},
    {label: "Percent", value: "Percent"},
    {label: "Price", value: "Price"},
  ];
  
  export const cartOfferTargetTypes = [
    {label: "None", value: null},
    {label: "Shipping", value: "Shipping"},
    {label: "Delivery", value: "Delivery"},
    {label: "Shipping & Delivery", value: "Shipping & Delivery"},
  ];

  export const CartOfferStatusList = [
    {label: "Active", value: "Active"},
    {label: "Disabled", value: "Disabled"},
  ];

  export const cartOffersTableHead = [
    {
        id: 'title',
        numeric: true,
        label: 'Title',
      },
      {
        id: 'discount',
        numeric: true,
        label: 'Discount',
      },
      {
        id: 'discountTarget',
        numeric: true,
        label: 'Discount Target',
      },
      {
        id: 'type',
        numeric: true,
        label: 'Type',
      },
      {
        id: 'state',
        numeric: true,
        label: 'Status',
      },
      {
        id: 'createdAt',
        numeric: true,
        label: 'CreatedAt',
      },
      {
        id: '',
        numeric: false,
        label: '',
      },
]