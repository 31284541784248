import { 
    IoWarningOutline, IoWarning, IoSearch, IoNotificationsOutline, IoLockClosedOutline, IoEyeOffOutline,IoEyeOutline, IoCartOutline, IoAddCircleSharp, IoHomeOutline, IoHomeSharp, IoStorefrontOutline, IoStorefront, IoFilterCircleOutline, IoFilterCircle, IoAppsOutline, IoApps,
    IoEllipsisHorizontalOutline, IoEllipsisHorizontal, IoAlertCircleOutline, IoAlertCircle, IoChatboxEllipsesOutline, IoChatboxEllipses, IoCogOutline, IoCogSharp, IoCallOutline, IoLocationOutline, IoLogoFacebook, IoLogoInstagram, IoLogoTwitter, IoLogoYoutube, IoMailOutline, IoChevronForwardSharp,
    IoClose, IoEllipse, IoHeartSharp, IoManOutline, IoTodayOutline, IoColorFilterOutline, IoArrowRedoOutline, IoOptions, IoChevronDownSharp, IoChevronBackSharp, IoChevronUpSharp, IoMenu, IoStar, IoCreate, IoCreateOutline, IoAddCircleOutline, IoRemoveCircleOutline, IoPencilOutline, IoTrashOutline,
    IoMoonOutline, IoSunnyOutline, IoGrid, IoGridOutline, IoExitOutline, IoPersonOutline, IoPerson, IoPeopleOutline, IoPeople, IoNotifications, IoCheckmark, IoCopy, IoCopyOutline, IoTrailSignOutline, IoTrailSign, IoHeadsetOutline, IoHeadset, IoPersonAdd, IoImagesOutline, IoSaveOutline, IoLanguageOutline,
    IoTimeOutline, IoCloseCircleOutline, IoRefreshCircleOutline, IoRemoveCircle, IoLogoUsd, IoPricetagOutline, IoPricetag, IoLogoApple, IoLogoBitcoin, IoLogoGoogle, IoLogoPaypal, IoCard, IoTrendingUp, IoSparkles, IoCart, IoColorFilterSharp, IoEllipsisHorizontalCircleOutline, IoWallet, IoWalletOutline, IoCarSportOutline, IoCarSport,
    IoDesktopSharp,
    IoPrint,
    IoPrintOutline,
    IoNewspaperOutline,
    IoCall,
    IoLogoWhatsapp,
    IoStatsChartOutline,
    IoStatsChart,
    IoPieChartOutline,
    IoPieChart,
    IoShirt,
    IoShirtOutline,
    IoImages,
    IoPricetagsOutline,
    IoPricetags,
    IoCheckmarkCircle,
    IoCheckmarkDoneCircle,
    IoCheckmarkDoneCircleOutline,
    IoArrowForward,
    IoArrowDown,
    IoWalletSharp,
    IoSwapHorizontalOutline,
} from 'react-icons/io5';

export const Icons = {
    products: IoImagesOutline,
    productsFill: IoImages,
    outfits: IoShirtOutline,
    outfitsFill: IoShirt,
    search: IoSearch,
    forward: IoChevronForwardSharp,
    back: IoChevronBackSharp,
    up: IoChevronUpSharp,
    down: IoChevronDownSharp,
    cart: IoCartOutline,
    cartFill: IoCart,
    show: IoEyeOutline,
    hide: IoEyeOffOutline,
    lock: IoLockClosedOutline,
    plus: IoAddCircleOutline,
    plusFill: IoAddCircleSharp,
    minus: IoRemoveCircleOutline,
    minusFill: IoRemoveCircle,
    warning: IoWarningOutline,
    warningFill: IoWarning,
    close: IoClose,
    closeCircle: IoCloseCircleOutline,
    reloadCircle: IoRefreshCircleOutline,
    check: IoCheckmark,
    menu: IoMenu,
    ellipseFill: IoEllipse,
    heartFill: IoHeartSharp,
    man: IoManOutline,
    date: IoTodayOutline,
    color: IoColorFilterOutline,
    share: IoArrowRedoOutline,
    filterIcon: IoOptions,
    starFill: IoStar,
    circleFill: IoEllipse,
    post: IoCreateOutline,
    postFill: IoCreate,
    pencil: IoPencilOutline,
    trash: IoTrashOutline,
    sun: IoSunnyOutline,
    moon: IoMoonOutline,
    logout: IoExitOutline,
    images: IoImagesOutline,
    save: IoSaveOutline,
    language: IoLanguageOutline,
    cash: IoLogoUsd,
    apple: IoLogoApple,
    bitcoin: IoLogoBitcoin,
    google: IoLogoGoogle,
    paypal: IoLogoPaypal,
    creditCard: IoCard,
    trend: IoTrendingUp,
    best: IoSparkles,
    // bottom bar icons...
    home: IoHomeOutline,
    homeFill: IoHomeSharp,
    store: IoStorefrontOutline,
    storeFill: IoStorefront,
    orders: IoFilterCircleOutline,
    ordersFill: IoFilterCircle,
    sections: IoAppsOutline,
    sectionsFill: IoApps,
    grid: IoGridOutline,
    gridFill: IoGrid,
    product: IoCopyOutline,
    productFill: IoCopy,
    groups: IoColorFilterOutline,
    groupsFill: IoColorFilterSharp,
    group: IoPeopleOutline,
    groupFill: IoPeople,
    addPersonFill: IoPersonAdd,
    person: IoPersonOutline,
    offer: IoPricetagOutline,
    offerFill: IoPricetag,
    personFill: IoPerson,
    transaction: IoTrailSignOutline,
    transactionFill: IoTrailSign,
    support: IoHeadsetOutline,
    supportFill: IoHeadset,
    more: IoEllipsisHorizontalOutline,
    moreFill: IoEllipsisHorizontal,
    about: IoAlertCircleOutline,
    aboutFill: IoAlertCircle,
    contact: IoChatboxEllipsesOutline,
    contactFill: IoChatboxEllipses,
    notifications: IoNotificationsOutline,
    notificationsFill: IoNotifications,
    settings: IoCogOutline,
    settingsFill: IoCogSharp,
    time: IoTimeOutline,
    // about page icons...
    call: IoCallOutline,
    callFill: IoCall,
    whatsapp: IoLogoWhatsapp,
    location: IoLocationOutline,
    facebook: IoLogoFacebook,
    instagram: IoLogoInstagram,
    twitter: IoLogoTwitter,
    youtube: IoLogoYoutube,
    mail: IoMailOutline,
    menu3: IoEllipsisHorizontalCircleOutline,
    wallet: IoWalletOutline,
    walletFill: IoWallet,
    car: IoCarSportOutline,
    carFill: IoCarSport,
    monitor: IoDesktopSharp,
    print: IoPrintOutline,
    printFill: IoPrint,
    sheet: IoNewspaperOutline,
    chart2: IoStatsChartOutline,
    chart2Fill: IoStatsChart,
    chart3: IoPieChartOutline,
    chart3Fill: IoPieChart,
    brands : IoPricetagsOutline ,
    brandsFill : IoPricetags, 
    checkCircle : IoCheckmarkDoneCircleOutline,
    arrowRight : IoArrowForward,
    arrowDown : IoArrowDown,
    walletIcon : IoWalletOutline,
    exchange: IoSwapHorizontalOutline
}