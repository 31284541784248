import { initialErrors } from "../Constants/Teams.constants";
import { Types } from "../Constants/Types";
import TeamType from "../Interfaces/team-interface";

const TeamsReducer = (state: TeamType['status'] = { 
        team: undefined,
        teams: [],
        lteams: false,
        lteam: false,
        ldelete: false,
        error: initialErrors
    },
    action: TeamType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_TEAMS:
            return {...state, teams: action.payload};

        case Types.FETCH_TEAM:
            return {...state, team: action.payload};

        case Types.CREATE_TEAM:
            return {...state, teams: [action.payload, ...state.teams]};

        case Types.UPDATE_TEAM: 
            return {...state, teams: state.teams.map(team => team._id === action.payload.id ? action.payload : team)};

        case Types.STATE_TEAM: 
            return {...state, teams: state.teams.map(team => team._id === action.payload.id ? {...team, ...action.payload} : team), team: {...state.team, state: action.payload.state}};

        case Types.DELETE_TEAM: 
            return {...state, teams: state.teams.filter(team => team._id !== action.payload)}

        case Types.START_TEAMS:
            return {...state, lteams: true};

        case Types.END_TEAMS:
            return {...state, lteams: false};

        case Types.START_TEAM:
            return {...state, lteam: true};

        case Types.END_TEAM:
            return {...state, lteam: false};

        case Types.ERROR_TEAM:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_TEAM:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default TeamsReducer;