import { initialErrors } from "../Constants/Order-notes.constants";
import { Types } from "../Constants/Types";
import OrderNoteType from "../Interfaces/order-notes.interface";

const OrderNotesReducer = (state: OrderNoteType['status'] = { 
        orderNote: undefined,
        orderNotes: [],
        ordersCartLoading: [],
        lorderNotes: false,
        lorderNote: false,
        error: initialErrors
    },
    action: OrderNoteType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_ORDER_NOTES:
            return {...state, orderNotes: action.payload};

        case Types.FETCH_ORDER_NOTE:
            return {...state, orderNote: action.payload};

        case Types.CREATE_ORDER_NOTE:
            return {...state, orderNotes: [action.payload, ...state.orderNotes]};

        case Types.UPDATE_ORDER_NOTE: 
            return {...state, orderNotes: state.orderNotes.map(orderNote => orderNote._id === action.payload._id ? action.payload : orderNote)};

        case Types.DELETE_ORDER_NOTE: 
            return {...state, orderNotes: state.orderNotes.filter(orderNote => orderNote._id !== action.payload)}

        case Types.START_ORDER_NOTES:
            return {...state, lorderNotes: true};

        case Types.END_ORDER_NOTES:
            return {...state, lorderNotes: false};

        case Types.START_GET_CART_URL:
            return {...state, ordersCartLoading: [...state.ordersCartLoading, action.payload]};

        case Types.END_GET_CART_URL:
            return {...state, ordersCartLoading: state.ordersCartLoading.filter(order => order !== action.payload)}

        case Types.ERROR_ORDER_NOTE:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_ORDER_NOTE:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default OrderNotesReducer;