import { initialStoreErrors } from "../Constants/Settings.constants";
import { Types } from "../Constants/Types";
import PromoCodeType from "../Interfaces/promo-codes.interface";
import SettingType from "../Interfaces/settings.interface";

const SettingsReducer = (state: SettingType['status'] = { 
        store: undefined,
        storeSettings: undefined,
        lstore: false,
        lstoreSettings: false,
        error: initialStoreErrors
    },
    action: PromoCodeType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_STORE:
            return {...state, store: action.payload};

        case Types.UPDATE_STORE: 
            return {...state, store: action.payload};
        
        case Types.START_STORE:
            return {...state, lstore: true};

        case Types.END_STORE:
            return {...state, lstore: false};

        case Types.FETCH_STORE_SETTINGS:
            return {...state, storeSettings: action.payload};

        case Types.UPDATE_STORE_SETTINGS: 
            return {...state, storeSettings: action.payload};
        
        case Types.START_STORE_SETTINGS:
            return {...state, lstoreSettings: true};

        case Types.END_STORE_SETTINGS:
            return {...state, lstoreSettings: false};

        case Types.ERROR_STORE:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_STORE:
            return {...state, error: initialStoreErrors}

        default: 
            return state
    }
}

export default SettingsReducer;