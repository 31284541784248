import { initialErrors } from "../Constants/Ads.constants";
import { Types } from "../Constants/Types";
import OutfitType from "../Interfaces/outfits.interface";

const OutfitsReducer = (state: OutfitType['status'] = { 
        outfit: undefined,
        outfits: [],
        results: [],
        loutfits: false,
        loutfit: false,
        lupdate: false,
        ldelete: false,
        pages: 0,
        resultPages: 0,
        error: initialErrors
    },
    action: OutfitType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_OUTFITS:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_OUTFITS:
            return {...state, outfits: action.payload, pages: action.pages};

        case Types.FETCH_OUTFIT:
            return {...state, outfit: action.payload};

        case Types.CREATE_OUTFIT:
            return {...state, outfits: [action.payload, ...state.outfits]};

        case Types.UPDATE_OUTFIT: 
            return {...state, outfits: state.outfits.map(outfit => outfit._id === action.payload.id ? action.payload : outfit)};
        
        case Types.STATE_OUTFIT: 
            return {...state, outfits: state.outfits.map(outfit => outfit._id === action.payload.id ? {...outfit, ...action.payload} : outfit), outfit: {...state.outfit, state: action.payload.state}};

        case Types.DELETE_OUTFIT: 
            return {...state, outfits: state.outfits.filter(outfit => outfit._id !== action.payload)}

        case Types.START_OUTFITS:
            return {...state, loutfits: true};

        case Types.END_OUTFITS:
            return {...state, loutfits: false};

        case Types.START_OUTFIT:
            return {...state, loutfit: true};

        case Types.END_OUTFIT:
            return {...state, loutfit: false};

        case Types.START_OUTFIT_UPDATE:
            return {...state, lupdate: true};

        case Types.END_OUTFIT_UPDATE:
            return {...state, lupdate: false};

        case Types.START_OUTFIT_DELETE:
            return {...state, ldelete: true};

        case Types.END_OUTFIT_DELETE:
            return {...state, ldelete: false};

        case Types.ERROR_OUTFIT:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_OUTFIT:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default OutfitsReducer;