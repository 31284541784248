
export const initialErrors = { 
    logo: String() || null,
    title: String() || null,
    type: String() || null,
    discount: String() || null,
    discountType: String() || null,
    discountTarget: String() || null,
  };
  export type initialErrorsType = typeof initialErrors;
  
  export const initialPaymentMethod = {
    title: {
        main: String(),
        en: String() || null,
        kr: String() || null
    },
    description: {
      main: String() || null,
      en: String() || null,
      kr: String() || null
    } || null,
    type: String() || null,
    provider: String() || null,
    activatedDiscount: Boolean() || null,
    discount: {
      discount: Number() || null,
      type: String() || null,
      discountTarget: String() || null
    } || null,
  };
  export type initialPaymentMethodType = typeof initialPaymentMethod;
  
  export const initialUpdatePaymentMethod = {
    id: String(),
    title: {
        main: String(),
        en: String() || null,
        kr: String() || null
    },
    description: {
      main: String() || null,
      en: String() || null,
      kr: String() || null
    } || null,
    type: String() || null,
    provider: String() || null,
    activatedDiscount: Boolean() || null,
    discount: {
      discount: Number() || null,
      type: String() || null,
      discountTarget: String() || null
    } || null,
  };

  export const paymentMethodStatusList = [
    {label: "Active", value: "Active"},
    {label: "Maintenance", value: "Maintenance"},
    {label: "Soon", value: "Soon"},
    {label: "Disabled", value: "Disabled"}
  ];
  
  
  export const paymentMethodTypes = [
      {label: "None", value: null},
      {label: "Credit Cards", value: "Credit Cards"},
      {label: "Zain Cash", value: "Zain Cash"},
      {label: "Fast Pay", value: "Fast Pay"},
      {label: "Wallet", value: "Wallet"},
      {label: "APS", value: "APS"},
      {label: "Other Payments", value: "Other Payments"},
  ];

  export const discountTypes = [
    {label: "None", value: null},
    {label: "Percent", value: "Percent"},
    {label: "Price", value: "Price"},
  ];
  
  export const discountTargetTypes = [
    {label: "None", value: null},
    {label: "Shipping", value: "Shipping"},
    {label: "Delivery", value: "Delivery"},
    {label: "Total", value: "Total"},
  ];