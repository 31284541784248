
export const initialErrors = { 
  image: String() || null,
  title: String() || null,
  scrapingItem: String() || null,
  type: String() || null,
  mainSection: String() || null
};
export type initialErrorsType = typeof initialErrors;

export const initialAd = {
  title: {
      main: "",
      en: String() || null,
      kr: String() || null
  },
  target: String() || null,
  type: String() || null,
  scrapingItem: String() || null,
  mainSection: String() || null
};

export const initialUpdateAd = {
  id: "",
  title: {
      main: "",
      en: String() || null,
      kr: String() || null
  },
  target: String() || null,
  type: String() || null,
  scrapingItem: String() || null,
  mainSection: String() || null
};

export const adsTypes = [
  {label: "None", value: null},
  {label: "Main", value: "Main"},
  // {label: "Category", value: "Category"},
  {label: "Products", value: "AddPage"}, 
];

export const adsTargets = [
    {label: "None", value: null},
    {label: "Scraping Item", value: "ScrapingItem"},
    // {label: "Category", value: "Category"},
    {label: "Section", value: "Section"}, 
];

export const adsTableHead = [
    {
      id: 'title',
      numeric: true,
      label: 'Title',
    },
    {
      id: 'target',
      numeric: true,
      label: 'Target',
    },
    {
      id: 'position',
      numeric: true,
      label: 'Position',
    },
    {
      id: 'state',
      numeric: true,
      label: 'Status',
    },
    {
      id: 'createdAt',
      numeric: true,
      label: 'CreatedAt',
    },
    {
      id: '',
      numeric: false,
      label: '',
    },
  ];