
export const initialErrors = { 
    admin: String() || null,
    name: String() || null,
    phoneNumber: String() || null,
    positionName: String() || null,
    image: String() || null,
}
  
  export const initialTeam = {
    admin: String() || null,
    name: String() || null,
    phoneNumber: String() || null,
    positionName: String() || null,
  };
  
  export type initialTeamType = typeof initialTeam;
  export type initialErrorsType = typeof initialErrors;
  
  export const teamsTableHead = [
      {
        id: 'name',
        numeric: true,
        label: 'Name',
      },
      {
        id: 'phoneNumber',
        numeric: true,
        label: 'Phone Number',
      },
      {
        id: 'postionName',
        numeric: true,
        label: 'Postion title',
      },
      {
        id: 'state',
        numeric: true,
        label: 'Status',
      },
      {
        id: 'createdAt',
        numeric: true,
        label: 'CreatedAt',
      },
      {
        id: '',
        numeric: false,
        label: '',
      },
    ];