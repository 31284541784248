import { initialErrors } from "../Constants/Order-histories.constants";
import { Types } from "../Constants/Types";
import OrderHistoryType from "../Interfaces/order-histories.interface";

const OrderHistoryReducer = (state: OrderHistoryType['status'] = { 
        orderHistory: undefined,
        orderHistories: [],
        results: [],
        lorderHistories: false,
        pages: 0,
        resultPages: 0,
        error: initialErrors
    },
    action: OrderHistoryType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_ORDER_HISTORIES:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_ORDER_HISTORIES:
            return {...state, orderHistories: action.payload};

        case Types.FETCH_ORDER_HISTORY:
            return {...state, orderHistory: action.payload};

        case Types.CREATE_ORDER_HISTORY:
            return {...state, orderHistories: [action.payload, ...state.orderHistories]};

        case Types.UPDATE_ORDER_HISTORY: 
            return {...state, orderHistories: state.orderHistories.map(orderHistory => orderHistory._id === action.payload.id ? action.payload : orderHistory)};
        
        case Types.STATE_ORDER_HISTORY: 
            return {...state, orderHistories: state.orderHistories.map(orderHistory => orderHistory._id === action.payload.id ? {...orderHistory, ...action.payload} : orderHistory), orderHistory: {...state.orderHistory, state: action.payload.state}};

        case Types.DELETE_ORDER_HISTORY: 
            return {...state, orderHistories: state.orderHistories.filter(orderHistory => orderHistory._id !== action.payload)}

        case Types.START_ORDER_HISTORIES:
            return {...state, lorderHistories: true};

        case Types.END_ORDER_HISTORIES:
            return {...state, lorderHistories: false};

        case Types.ERROR_ORDER_HISTORY:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_ORDER_HISTORY:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default OrderHistoryReducer;