import { Box, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Icons } from '../Constants/Icons';

const Page404 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["main"])
  return (
   <>
    <Container maxWidth='lg'>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '55vh'}}>
          <Icons.warning style={{fontSize: '4rem', color: '#f22'}}/>
          <Typography variant='h1' color='textPrimary'>404</Typography>
          <Typography variant='h4' color='textPrimary' mb={3}>{t("Not found page")}</Typography>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5}}>
            <Typography variant='h6' color='textPrimary'>{t("Go back")}</Typography>
            <Typography variant='h6' px={1} color='primary' sx={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate(-1)}>{t("Click here")}</Typography>
          </Box>
      </Box>
    </Container>
   </>
  )
}

export default Page404