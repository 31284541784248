import { initialErrors } from "../Constants/Categories.constants";
import { Types } from "../Constants/Types";
import CategoryType from "../Interfaces/main-section-category.interface";

const MainSectionCategoriesReducer = (state: CategoryType['status'] = { 
        category: undefined,
        categories: [],
        results: [],
        lcategories: false,
        pages: 0,
        resultPages: 0,
        error: initialErrors
    },
    action: CategoryType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_CATEGORIES:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_CATEGORIES:
            return {...state, categories: action.payload, pages: action.pages};

        case Types.FETCH_CATEGORY:
            return {...state, category: action.payload};

        case Types.CREATE_CATEGORY:
            return {...state, categories: [action.payload, ...state.categories]};

        case Types.UPDATE_CATEGORY: 
            return {...state, categories: state.categories.map(category => category._id === action.payload.id ? action.payload : category)};
        
        case Types.STATE_CATEGORY: 
            return {...state, categories: state.categories.map(category => category._id === action.payload.id ? {...category, ...action.payload} : category), category: {...state.category, state: action.payload.state}};

        case Types.DELETE_CATEGORY: 
            return {...state, categories: state.categories.filter(category => category._id !== action.payload)}

        case Types.START_CATEGORIES:
            return {...state, lcategories: true};

        case Types.END_CATEGORIES:
            return {...state, lcategories: false};

        case Types.ERROR_CATEGORY:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_CATEGORY:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default MainSectionCategoriesReducer;