import { Types } from '../Constants/Types';
import AuthTypes from "../Interfaces/auth-interface";


const errors = {
    email: '',
    phoneNumber: '',
    username: '',
    password: ''
}

const AuthReducer = (state :AuthTypes['status']= {info: undefined, lauth: false, linfo: false, errors: errors}, action : {type: string, payload: any | undefined}) => {

    switch (action.type) {
        case Types.S_LOADING:
            return {...state, lauth: true};

        case Types.E_LOADING: 
            return {...state, lauth: false};

        case Types.START_INFO_LOADING:
            return {...state, linfo: true};

        case Types.END_INFO_LOADING: 
            return {...state, linfo: false};

        case Types.LOGIN:
            return {...state, info: action.payload};

        case Types.INFO: 
            return {...state, info: action.payload};

        case Types.UPDATE_PROFILE: 
            return {...state, info: {...state.info, ...action.payload}};

        case Types.LOGOUT: 
            return {...state, info: undefined};

        case Types.AUTH_ERROR: 
            let key = action.payload.split(" ")[0];
            let value = action.payload.split(" ")[1];
            return {...state, errors: {...errors, [key]: value}}

        default: return state
    }
}

export default AuthReducer;