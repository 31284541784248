import { useState, useMemo, createContext, useContext } from "react";

import { createTheme, ThemeProvider, responsiveFontSizes, lighten } from '@mui/material/styles';

import { grey, green, teal, red, pink, orange, purple} from '@mui/material/colors';
import Cookie from 'js-cookie';
import ContextTypes from "../Interfaces/context.interface";
import { ContentPageColor } from "../Hooks/GeneralFunc";
import { CssBaseline, useMediaQuery } from "@mui/material";


export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const useColorMode = () => useContext(ColorModeContext);

export default function ToggleColorMode({children}: ContextTypes['childrenProps']) {
   
    const cookieMode = Cookie.get('mode') || 'light';
    const [mode, setMode] = useState<any>(cookieMode);
    const sm = useMediaQuery('(max-width:600px)');
    const styleOptions = {
      main: "#000000",
      black: "rgb(18,18,18)",
      white: "#f7f7f7",
      borderRadius: "10px",
      buttonBR: "15px",
      textFieldBR: "20px",
      light: {
        primary: "#7A577A",
        hover: "#333333",
        contrastText: "#f7f7f7",
        secondary: grey[300],
        secondaryText: "#181818"
      },
      dark: {
        primary: "#7A577A",
        hover: "#cccccc",
        contrastText: "#ffffff",
        secondary: grey[800],
      }
    }

    ContentPageColor(cookieMode === 'light' ? styleOptions.white : styleOptions.black);
 
    const colorMode = useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode: string) => {
            if(prevMode === "light") {
              ContentPageColor(styleOptions.black);
              Cookie.set('mode', 'dark', {expires: 30, secure: true, sameSite: 'strict'});
              return 'dark'
            } else {
              ContentPageColor(styleOptions.white);
               Cookie.set('mode', 'light', {expires: 30, secure: true, sameSite: 'strict'});
              return 'light'
            }
          });
        },
      }),
      [],
    );
  
    let theme = useMemo(
      () =>
        createTheme({
          typography: {
            fontFamily: 'Roboto, Almarai',
            h1: {
              fontSize: sm ? "1.2rem" : "1.3rem",
              fontWeight: 500
            },
            h2: {
              fontSize: sm ? "1.1rem" : "1.2rem",
              fontWeight: 450
            },
            h3: {
              fontSize: sm ? "1rem" : "1.1rem",
              fontWeight: 440
            },
            h4:{
              fontSize: sm ? "0.95rem" : "1.05rem",
              fontWeight: 430
            },
            h5: {
              fontSize: sm ? "0.9rem" : "1rem",
              fontWeight: 420
            },
            h6: {
              fontSize: sm ? "0.85rem" : "0.95rem",
              fontWeight: 410
            },
            subtitle1: {
              fontSize: sm ? "0.80rem" : "0.9rem",
              fontWeight: 410
            },
            subtitle2: {
              fontSize: sm ? "0.75rem" : "0.85rem",
              fontWeight: 410
            },
            body1: {
              fontSize: sm ? "0.75rem" : "0.8rem",
              fontWeight: 400
            },
            body2: {
              fontSize: sm ? "0.65rem" : "0.75rem",
              fontWeight: 400
            },
            caption: {
              fontSize: sm ? "0.55rem" : "0.65rem"
            }
          },
          components: {
            MuiButton: {
              styleOverrides: {
                root: ({ ownerState }) => ({
                  ...(ownerState.variant === 'contained' ?
                     {
                      ":hover": {
                        backgroundColor: 
                        ownerState.color === 'primary' ? mode === 'light' ? styleOptions.light.hover : styleOptions.dark.hover
                        : 
                        ownerState.color === 'secondary' ? mode === 'light' ? grey[300] : grey[700]
                        :
                        ownerState.color === 'success' ? mode === 'light' ? teal[300] : lighten(teal[300], 0.1)
                        :
                        ownerState.color === 'error' ? mode === 'light' ? red[500] : lighten(red[400], 0.1)
                        :
                        ownerState.color === 'warning' ? mode === 'light' ? lighten(orange[800], 0.1) : lighten(orange[700], 0.1)
                        :
                        ownerState.color === 'info' && lighten(pink[400], 0.1)
                      },
                      borderRadius: styleOptions.buttonBR,
                      textTransform: 'none'
                    }
                    :
                    ownerState.variant === 'outlined' ? 
                    {
                      ":hover": {
                        backgroundColor: 
                        ownerState.color === 'primary' ? styleOptions.light.primary
                        :
                        ownerState.color === 'secondary' ? mode === 'light' ? styleOptions.light.secondary : grey[800]
                        :
                        ownerState.color === 'success' ? mode === 'light' ? teal[300] : lighten(teal[300], 0.1)
                        :
                        ownerState.color === 'error' ? mode === 'light' ? red[400] : lighten(red[400], 0.1)
                        :
                        ownerState.color === 'warning' ? mode === 'light' ? lighten(orange[800], 0.1) : lighten(orange[700], 0.1)
                        :
                        ownerState.color === 'info' && lighten(pink[400], 0.1),

                        color: ownerState.color === 'secondary' ? mode === 'light' ? styleOptions.black : styleOptions.white : styleOptions.white
                      },
                      color: ownerState.color === 'secondary' ? (mode === 'light' ? grey[800] : grey[300]) : ownerState.color,
                      borderColor: ownerState.color === 'secondary' ? grey[500] : ownerState.color,
                      borderRadius: styleOptions.buttonBR,
                      textTransform: 'none'
                    }
                    : 
                    ownerState.variant === 'text' && 
                    {
                      ":hover": {
                        color: 
                        ownerState.color === 'primary' ? mode === 'light' ? purple[300] : purple[300]
                        :
                        ownerState.color === 'secondary' ? mode === 'light' ? grey[800] : styleOptions.dark.contrastText
                        :
                        ownerState.color === 'success' ? mode === 'light' ? teal[700] : teal[200]
                        :
                        ownerState.color === 'error' ? mode === 'light' ? red[700] : red[200]
                        :
                        ownerState.color === 'warning' ? mode === 'light' ? orange[900] : orange[200]
                        :
                        ownerState.color === 'info' && mode === 'light' ? pink[700] : pink[200]
                        ,
                        backgroundColor: "rgba(0,0,0, 0)"
                      },
                      color: ownerState.color === 'secondary' ? (mode === 'light' ? grey[600] : grey[400]) : ownerState.color,
                      textTransform: 'none'
                    }
                    ),
                }),
              },
            },
            MuiTextField: {
              styleOverrides: {
                root: ({ownerState}) => ({ //ownerState.variant === 'outlined' &&
                  ...( document.dir === 'rtl' ? {
                    'fieldset': {
                        borderRadius: styleOptions.textFieldBR,
                        textAlign: 'right',
                      },
                      outline: 'none',
                      '& label.Mui-focused': {
                        //right: 10
                        // marginRight: '1%'
                      },
                      'label': {
                        right: 30,
                        transformOrigin: "top right"

                      },
                      'p': {
                        textAlign: 'right',
                      },
                  }
                  :
                  {
                    'fieldset': {
                      borderRadius: styleOptions.textFieldBR,
                    },
                    outline: 'none',
                    'input': {
                      fontSize: sm ? "0.9rem" : "0.9rem",
                      height: sm ? "1em" : "1.2em",
                      borderRadius: styleOptions.textFieldBR
                    }
                  }
                  )
                }),
              },
            },
            MuiPaper: {
              styleOverrides: {
                root: ({ownerState}) => ({
                  ...({
                    borderRadius: styleOptions.borderRadius,
                    boxShadow: '-1px 0 15px 2px rgba(0,0,0,0.05)',
                    WebkitBoxShadow: '-1px 0 15px 2px rgba(0,0,0,0.05)',
                    MozBoxShadow: '-1px 15px 15px 2px rgba(0,0,0,0.05)',
                    WebkitBackdropFilter: 'blur(10px)',
                    backdropFilter: 'blur(10px)',
                  })
                })
              }
            },
            MuiAppBar: {
              styleOverrides: {
                root: ({ownerState}) => ({
                  ...({
                    boxShadow: '-1px 0 15px 2px rgba(0,0,0,0.02)',
                    WebkitBoxShadow: '-1px 0 15px 2px rgba(0,0,0,0.02)',
                    MozBoxShadow: '-1px 15px 15px 2px rgba(0,0,0,0.02)',
                    WebkitBackdropFilter: 'blur(10px)',
                    backdropFilter: 'blur(10px)',
                    backgroundColor: mode === 'light' ? 'rgba(245, 245, 245, 0.6)' : 'rgba(24, 24, 24, 0.8)',
                    borderBottom: '0.5px solid rgba(100,100,100,0.05)',
                  })
                })
              }
            },
            MuiSelect: {
              styleOverrides: {
                root: ({ownerState}) => ({
                  ...({
                    borderRadius: styleOptions.buttonBR,
                    '.MuiOutlinedInput-input': {
                      padding: "8px 10px",
                    }
                  })
                })
              }
            }
          },
          direction: document.dir as any,
          palette: {
            mode,
            ...(mode === 'light'
            ? {
                // palette values for light mode
                primary: {
                  main: styleOptions.light.primary,
                  contrastText: styleOptions.light.contrastText,
                },
                secondary: {
                  main: styleOptions.light.secondary,
                  contrastText: styleOptions.light.secondaryText,
                },
                text: {
                  primary: '#000',
                  secondary: grey[700]
                },
                background: {
                  default: '#f7f7f7',
                  paper: 'rgba(245, 245, 245, 0.6)',
                },
                success: {
                  main: teal[400],
                  contrastText: styleOptions.light.contrastText,
                },
                 error: {
                  main: red[500],
                  contrastText: styleOptions.light.contrastText,
                },
                 warning: {
                  main: orange[700],
                  contrastText: styleOptions.light.contrastText,
                },
                 info: {
                  main: pink[400],
                  contrastText: styleOptions.light.contrastText,
                }
              }
            : {
                // palette values for dark mode
                primary: {
                  main: styleOptions.dark.primary,
                  contrastText: styleOptions.dark.contrastText,
                },
                secondary: {
                  main: styleOptions.dark.secondary,
                  contrastText: styleOptions.dark.contrastText,
                },
                text: {
                  primary: '#f7f7f7',
                  secondary: grey[500]
                },
                background: {
                  default: styleOptions.black,
                  paper: 'rgba(10, 10, 10, 0.8)',
                },
                success: {
                  main: teal[300],
                  contrastText: styleOptions.dark.contrastText,
                },
                 error: {
                  main: red[400],
                  contrastText: styleOptions.dark.contrastText,
                },
                 warning: {
                  main: orange[500],
                  contrastText: styleOptions.dark.contrastText,
                },
                  info: {
                  main: pink[400],
                  contrastText: styleOptions.dark.contrastText,
                }
              }),
          },
        }),
      [mode],
    );
  theme = responsiveFontSizes(theme, {
    breakpoints: ["sm", "lg"],
    variants: ["h1"]
  })
    return (
        <ThemeProvider theme={theme}>
      <ColorModeContext.Provider value={colorMode}>
        <CssBaseline />
          {children}
      </ColorModeContext.Provider>
        </ThemeProvider>
    );
  }