import { initialErrors } from '../Constants/Admins.constants';
import { Types } from '../Constants/Types';
import AdminType from '../Interfaces/admin-interface';

const AdminsReducer = (state :AdminType['status']= {
    admin: undefined,
    admins: [],
    ladmins: false,
    ladmin: false,
    lwallet: false,
    lpassword: false,
    ldelete: false,
    error: initialErrors
}, action : AdminType['action']) => {
    
    switch (action.type) {
        
        case Types.FETCH_ADMINS:
                return {...state, admins: action.payload};

        case Types.FETCH_ADMIN:
            return {...state, admin: action.payload};

        case Types.CREATE_ADMIN: 
            return {...state, admins: [action.payload, ...state.admins]};

        case Types.UPDATE_ADMIN: 
            return {...state, admins: state.admins.map(admin => admin._id === action.payload.id ? action.payload : admin)};

        case Types.WALLET_ADMIN:
            if(action.payload.type === "Amount"){
                return {...state, admin: {...state.admin, wallet: {...state?.admin?.wallet, amount: 0}}};
            } else {
                return {...state, admin: {...state.admin, wallet: {...state?.admin?.wallet, points: 0}}};
            }

        case Types.STATE_ADMIN: 
            return {...state, admins: state.admins.map(admin => admin._id === action.payload.id ? {...admin, ...action.payload} : admin)}
        
        case Types.DELETE_ADMIN: 
            return {...state, admins: state.admins.filter(admin => admin._id !== action.payload)}

        case Types.START_ADMINS:
            return {...state, ladmins: true};

        case Types.END_ADMINS: 
            return {...state, ladmins: false};

        case Types.START_ADMIN:
            return {...state, ladmin: true};

        case Types.END_ADMIN: 
            return {...state, ladmin: false};

        case Types.START_WALLET_ADMIN:
            return {...state, lwallet: true};

        case Types.END_WALLET_ADMIN: 
            return {...state, lwallet: false};

        case Types.START_PASSWORD:
            return {...state, lpassword: true};

        case Types.END_PASSWORD: 
            return {...state, lpassword: false};

        case Types.START_DELETE:
            return {...state, ldelete: true};

        case Types.END_DELETE: 
            return {...state, ldelete: false};

        case Types.ERROR_ADMIN:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_ADMIN:
            return {...state, error: initialErrors}

        default: return state
    }
}

export default AdminsReducer;