import { Types } from '../Constants/Types';
import TransactionType from '../Interfaces/transaction.interface';

const TransactionsReducer = (
    state :TransactionType['status']= {
        transaction: undefined,
        transactions: [],
        amountTransactions: [], 
        pointsTransactions: [],
        adminTransactions: [],
        userTransactions: [],
        pages: 0,
        ltransactions: false,
        ltransaction: false
    },
    action : TransactionType['action']) => {
    switch (action.type) {

        case Types.FETCH_TRANSACTIONS:
            return {...state, transactions: action.payload, pages: action.pages};

        case Types.FETCH_AMOUNT_TRANSACTIONS:
            return {...state, amountTransactions: action.payload, pages: action.pages};

        case Types.FETCH_POINTS_TRANSACTIONS:
            return {...state, pointsTransactions: action.payload, pages: action.pages};

        case Types.FETCH_ADMIN_TRANSACTIONS:
            return {...state, adminTransactions: action.payload.data, pages: action.pages};

        case Types.FETCH_USER_TRANSACTIONS:
            return {...state, userTransactions: action.payload.data, pages: action.pages};

        case Types.FETCH_TRANSACTION:
            return {...state, transaction: action.payload};

        case Types.UPDATE_WALLET:
            if(action.payload.type === "Amount"){
                return {...state, amountTransactions: [action.payload, ...state.amountTransactions]};
            } else {
                return {...state, pointsTransactions: [action.payload, ...state.pointsTransactions]};
            }

        case Types.UPDATE_TRANSACTION: 
            return {...state, transactions: state.amountTransactions.map(transaction => transaction._id === action.payload.id ? {...transaction, ...action.payload} : transaction)};
        
        case Types.STATE_TRANSACTION: 
        return {...state, transactions: state.amountTransactions.map(transaction => transaction._id === action.payload.id ? {...transaction, ...action.payload} : transaction)};

        case Types.DELETE_TRANSACTION: 
            return {...state, transactions: state.amountTransactions.filter(transaction => transaction._id !== action.payload)}

        case Types.START_TRANSACTIONS:
            return {...state, ltransactions: true};

        case Types.END_TRANSACTIONS: 
            return {...state, ltransactions: false};

        case Types.START_TRANSACTION:
            return {...state, ltransaction: true};

        case Types.END_TRANSACTION: 
            return {...state, ltransaction: false};

        default: return state
    }
}

export default TransactionsReducer;