
import { Types } from '../Constants/Types';
import ReportType from '../Interfaces/reports.interface';

const ReportsReducer = (state: ReportType['status'] = {profits: {} as any, orders: {} as any, completedOrders: {} as any, users: {} as any, lprofit: false, lorders: false}, action: ReportType['action']) => {


    switch (action.type) {
        case Types.FETCH_ORDERS_REPORT:
            return {...state, orders: action.payload};

        case Types.FETCH_COMPLETED_ORDERS_REPORT:
            return {...state, completedOrders: action.payload};
                
        case Types.FETCH_PROFIT_REPORT:
            return {...state, profits: action.payload};

        case Types.FETCH_USERS_REPORT:
            return {...state, users: action.payload};

        case Types.START_REPORTS:
            return {...state, lprofit: true};

        case Types.END_REPORTS:
                return {...state, lprofit: false};

        default:
            return state
    }
}

export default ReportsReducer;