
export const initialErrors = { 
    title: String() || null,
  };
  export type initialErrorsType = typeof initialErrors;
  
  export const initialHistory = {
    title: String() || null
  };
  
  export const initialUpdateHistory = {
    id: "",
    title: String() || null
  };
  
  export const historiesTableHead = [
    {
      id: 'no',
      numeric: true,
      label: 'No',
    },
    {
      id: 'admin',
      numeric: true,
      label: 'Admin',
    },
    {
      id: 'title',
      numeric: true,
      label: 'Title',
    },
    {
      id: 'action',
      numeric: true,
      label: 'Action',
    },
    {
      id: 'previousTotal',
      numeric: true,
      label: 'Previous Total',
    },
    {
      id: 'currentTotal',
      numeric: true,
      label: 'Current Total',
    },
    {
      id: 'createdAt',
      numeric: true,
      label: 'CreatedAt',
    },
  ];