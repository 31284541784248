import { initialErrors } from "../Constants/Drivers.constants";
import { Types } from "../Constants/Types";
import DriverType from "../Interfaces/driver-interface";

const DriversReducer = (state: DriverType['status'] = { 
        driver: undefined,
        drivers: [],
        orders: [],
        ldrivers: false,
        lorders: false,
        ldriver: false,
        lwallet: false,
        lpassword: false,
        ldelete: false,
        pages: 0,
        error: initialErrors
    },
    action: DriverType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_DRIVERS:
            return {...state, drivers: action.payload, pages: action.pages};

        case Types.FETCH_DRIVER_ORDERS:
            return {...state, orders: action.payload, pages: action.pages};

        case Types.FETCH_DRIVER:
            return {...state, driver: action.payload};

        case Types.CREATE_DRIVER:
            return {...state, drivers: [action.payload, ...state.drivers]};

        case Types.UPDATE_DRIVER: 
            return {...state, drivers: state.drivers.map(driver => driver._id === action.payload.id ? action.payload : driver)};

            //? rest wallet...
        // case Types.WALLET_DRIVER:
        //     if(action.payload.type === "Amount"){
        //         return {...state, driver: {...state.driver, wallet: {...state?.driver?.wallet, amount: 0}}};
        //     } else {
        //         return {...state, driver: {...state.driver, wallet: {...state?.driver?.wallet, points: 0}}};
        //     }

        case Types.WALLET_DRIVER:
            if(action.payload.type === "Amount"){
                return {...state, driver: {...state.driver, wallet: {...state?.driver?.wallet, amount: action.payload.previous + action.payload.amount}}};
            } else {
                return {...state, driver: {...state.driver, wallet: {...state?.driver?.wallet, points: action.payload.previous + action.payload.amount}}};
            }
        
        case Types.STATE_DRIVER: 
            return {...state, drivers: state.drivers.map(driver => driver._id === action.payload.id ? {...driver, ...action.payload} : driver), driver: {...state.driver, state: action.payload.state}};

        case Types.DELETE_DRIVER: 
            return {...state, drivers: state.drivers.filter(driver => driver._id !== action.payload)}

        case Types.START_DRIVERS:
            return {...state, ldrivers: true};

        case Types.END_DRIVERS:
            return {...state, ldrivers: false};

        case Types.START_DRIVER_ORDERS:
            return {...state, lorders: true};

        case Types.END_DRIVER_ORDERS:
            return {...state, lorders: false};

        case Types.START_DRIVER:
            return {...state, ldriver: true};

        case Types.END_DRIVER:
            return {...state, ldriver: false};

        case Types.START_WALLET_DRIVER:
            return {...state, lwallet: true};

        case Types.END_WALLET_DRIVER: 
            return {...state, lwallet: false};

        case Types.ERROR_DRIVER:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_DRIVER:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default DriversReducer;