import { initialErrors } from "../Constants/Order-delivery-notes.constants";
import { Types } from "../Constants/Types";
import OrderDeliveryNoteType from "../Interfaces/order-delivery-notes.interface";

const OrderDeliveryNotesReducer = (state: OrderDeliveryNoteType['status'] = { 
        orderDeliveryNote: undefined,
        orderDeliveryNotes: [],
        lorderDeliveryNotes: false,
        lorderDeliveryNote: false,
        error: initialErrors
    },
    action: OrderDeliveryNoteType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_ORDER_DELIVERY_NOTES:
            return {...state, orderDeliveryNotes: action.payload};

        case Types.FETCH_ORDER_DELIVERY_NOTE:
            return {...state, orderDeliveryNote: action.payload};

        case Types.CREATE_ORDER_DELIVERY_NOTE:
            return {...state, orderDeliveryNotes: [action.payload, ...state.orderDeliveryNotes]};

        case Types.UPDATE_ORDER_DELIVERY_NOTE: 
            return {...state, orderDeliveryNotes: state.orderDeliveryNotes.map(orderDeliveryNote => orderDeliveryNote._id === action.payload._id ? action.payload : orderDeliveryNote)};

        case Types.DELETE_ORDER_DELIVERY_NOTE: 
            return {...state, orderDeliveryNotes: state.orderDeliveryNotes.filter(orderDeliveryNote => orderDeliveryNote._id !== action.payload)}

        case Types.START_ORDER_DELIVERY_NOTES:
            return {...state, lorderDeliveryNotes: true};

        case Types.END_ORDER_DELIVERY_NOTES:
            return {...state, lorderDeliveryNotes: false};

        case Types.ERROR_ORDER_DELIVERY_NOTE:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_ORDER_DELIVERY_NOTE:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default OrderDeliveryNotesReducer;