export enum Types {
  //? auth...
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  REFRESH = "REFRESH",
  INFO = "INFO",
  //* loading...
  S_LOADING = "S_LOADING",
  E_LOADING = "E_LOADING",
  START_INFO_LOADING = "START_INFO_LOADING",
  END_INFO_LOADING = "END_INFO_LOADING",

  //? home...
  FETCH_HOME = "FETCH_HOME",

  //? users...
  SEARCH_CUSTOMERS = "SEARCH_CUSTOMERS",
  FETCH_CUSTOMERS = "FETCH_CUSTOMERS",
  FETCH_CUSTOMER = "FETCH_CUSTOMER",
  FETCH_CUSTOMER_ORDERS = "FETCH_CUSTOMER_ORDERS",
  CREATE_CUSTOMER = "CREATE_CUSTOMER",
  UPDATE_CUSTOMER = "UPDATE_CUSTOMER",
  UPDATE_WALLET = "UPDATE_WALLET",
  STATE_CUSTOMER = "STATE_CUSTOMER",
  DELETE_CUSTOMER = "DELETE_CUSTOMER",
  NUMBERS_CUSTOMERS = "NUMBERS_CUSTOMERS",
  //* search...
  FETCH_CUSTOMERS_SEARCH = "FETCH_CUSTOMERS_SEARCH",
  //* loading...
  START_CUSTOMERS = "START_CUSTOMERS",
  END_CUSTOMERS = "END_CUSTOMERS",
  START_CUSTOMER = "START_CUSTOMER",
  END_CUSTOMER = "END_CUSTOMER",
  START_WALLET = "START_WALLET",
  END_WALLET = "END_WALLET",
  //* errors...
  ERROR_CUSTOMER = "ERROR_CUSTOMER",
  RESET_CUSTOMER = "RESET_CUSTOMER",

  START_DELETE = "START_DELETE",
  END_DELETE = "END_DELETE",

  //? admins...
  FETCH_ADMINS = "FETCH_ADMINS",
  FETCH_ADMIN = "FETCH_ADMIN",
  CREATE_ADMIN = "CREATE_ADMIN",
  UPDATE_ADMIN = "UPDATE_ADMIN",
  WALLET_ADMIN = "WALLET_ADMIN",
  STATE_ADMIN = "STATE_ADMIN",
  DELETE_ADMIN = "DELETE_ADMIN",
  //* loading...
  START_ADMINS = "START_ADMINS",
  END_ADMINS = "END_ADMINS",
  START_ADMIN = "START_ADMIN",
  END_ADMIN = "END_ADMIN",
  START_WALLET_ADMIN = "START_WALLET_ADMIN",
  END_WALLET_ADMIN = "END_WALLET_ADMIN",
  //* errors...
  ERROR_ADMIN = "ERROR_ADMIN",
  RESET_ADMIN = "RESET_ADMIN",

  //? drivers...
  FETCH_DRIVERS = "FETCH_DRIVERS",
  FETCH_DRIVER = "FETCH_DRIVER",
  CREATE_DRIVER = "CREATE_DRIVER",
  UPDATE_DRIVER = "UPDATE_DRIVER",
  WALLET_DRIVER = "WALLET_DRIVER",
  STATE_DRIVER = "STATE_DRIVER",
  DELETE_DRIVER = "DELETE_DRIVER",
  //* loading...
  START_DRIVERS = "START_DRIVERS",
  END_DRIVERS = "END_DRIVERS",
  START_DRIVER = "START_DRIVER",
  END_DRIVER = "END_DRIVER",
  START_WALLET_DRIVER = "START_WALLET_DRIVER",
  END_WALLET_DRIVER = "END_WALLET_DRIVER",
  //* errors...
  ERROR_DRIVER = "ERROR_DRIVER",
  RESET_DRIVER = "RESET_DRIVER",

  //? teams...
  FETCH_TEAMS = "FETCH_TEAMS",
  FETCH_TEAM = "FETCH_TEAM",
  CREATE_TEAM = "CREATE_TEAM",
  UPDATE_TEAM = "UPDATE_TEAM",
  STATE_TEAM = "STATE_TEAM",
  DELETE_TEAM = "DELETE_TEAM",
  //* loading...
  START_TEAMS = "START_TEAMS",
  END_TEAMS = "END_TEAMS",
  START_TEAM = "START_TEAM",
  END_TEAM = "END_TEAM",
  //* errors...
  ERROR_TEAM = "ERROR_TEAM",
  RESET_TEAM = "RESET_TEAM",

  //? advertisements...
  SEARCH_ADVERTISEMENTS = "SEARCH_ADVERTISEMENTS",
  FETCH_ADVERTISEMENTS = "FETCH_ADVERTISEMENTS",
  FETCH_ADVERTISEMENT = "FETCH_ADVERTISEMENT",
  CREATE_ADVERTISEMENT = "CREATE_ADVERTISEMENT",
  UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT",
  STATE_ADVERTISEMENT = "STATE_ADVERTISEMENT",
  POSITION_ADVERTISEMENT = "POSITION_ADVERTISEMENT",
  DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT",
  //* loading...
  START_ADVERTISEMENTS = "START_ADVERTISEMENTS",
  END_ADVERTISEMENTS = "END_ADVERTISEMENTS",
  START_ADVERTISEMENT = "START_ADVERTISEMENT",
  END_ADVERTISEMENT = "END_ADVERTISEMENT",
  //* errors...
  ERROR_ADVERTISEMENT = "ERROR_ADVERTISEMENT",
  RESET_ADVERTISEMENT = "RESET_ADVERTISEMENT",

  //? order-histories...
  SEARCH_ORDER_HISTORIES = "SEARCH_ORDER_HISTORIES",
  FETCH_ORDER_HISTORIES = "FETCH_ORDER_HISTORIES",
  FETCH_ORDER_HISTORY = "FETCH_ORDER_HISTORY",
  CREATE_ORDER_HISTORY = "CREATE_ORDER_HISTORY",
  UPDATE_ORDER_HISTORY = "UPDATE_ORDER_HISTORY",
  STATE_ORDER_HISTORY = "STATE_ORDER_HISTORY",
  POSITION_ORDER_HISTORY = "POSITION_ORDER_HISTORY",
  DELETE_ORDER_HISTORY = "DELETE_ORDER_HISTORY",
  //* loading...
  START_ORDER_HISTORIES = "START_ORDER_HISTORIES",
  END_ORDER_HISTORIES = "END_ORDER_HISTORIES",
  START_ORDER_HISTORY = "START_ORDER_HISTORY",
  END_ORDER_HISTORY = "END_ORDER_HISTORY",
  //* errors...
  ERROR_ORDER_HISTORY = "ERROR_ORDER_HISTORY",
  RESET_ORDER_HISTORY = "RESET_ORDER_HISTORY",

  //? sections...
  SEARCH_MAIN_SECTIONS = "SEARCH_MAIN_SECTIONS",
  FETCH_MAIN_SECTIONS = "FETCH_MAIN_SECTIONS",
  FETCH_MAIN_SECTION = "FETCH_MAIN_SECTION",
  CREATE_MAIN_SECTION = "CREATE_MAIN_SECTION",
  UPDATE_MAIN_SECTION = "UPDATE_MAIN_SECTION",
  STATE_MAIN_SECTION = "STATE_MAIN_SECTION",
  POSITION_MAIN_SECTION = "POSITION_MAIN_SECTION",
  DELETE_MAIN_SECTION = "DELETE_MAIN_SECTION",
  //* loading...
  START_MAIN_SECTIONS = "START_MAIN_SECTIONS",
  END_MAIN_SECTIONS = "END_MAIN_SECTIONS",
  START_MAIN_SECTION = "START_MAIN_SECTION",
  END_MAIN_SECTION = "END_MAIN_SECTION",
  //* errors...
  ERROR_MAIN_SECTION = "ERROR_MAIN_SECTION",
  RESET_MAIN_SECTION = "RESET_MAIN_SECTION",

  // //? products...
  SEARCH_PRODUCTS = "SEARCH_PRODUCTS",
  FETCH_PRODUCTS = "FETCH_PRODUCTS",
  FETCH_PRODUCT = "FETCH_PRODUCT",
  CREATE_PRODUCT = "CREATE_PRODUCT",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  STATE_PRODUCT = "STATE_PRODUCT",
  POSITION_PRODUCT = "POSITION_PRODUCT",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  //* loading...
  START_PRODUCTS = "START_PRODUCTS",
  END_PRODUCTS = "END_PRODUCTS",
  START_PRODUCT = "START_PRODUCT",
  END_PRODUCT = "END_PRODUCT",
  START_PRODUCT_UPDATE = "START_PRODUCT_UPDATE",
  END_PRODUCT_UPDATE = "END_PRODUCT_UPDATE",
  //* Ref orders...
  SEARCH_ORDER_PRODUCTS = "SEARCH_ORDER_PRODUCTS",
  FETCH_ORDER_PRODUCTS = "FETCH_ORDER_PRODUCTS",
  FETCH_ORDER_SELECTED_PRODUCTS = "FETCH_ORDER_SELECTED_PRODUCTS",
  //* errors...
  ERROR_PRODUCT = "ERROR_PRODUCT",
  RESET_PRODUCT = "RESET_PRODUCT",

  // //? outfits...
  SEARCH_OUTFITS = "SEARCH_OUTFITS",
  FETCH_OUTFITS = "FETCH_OUTFITS",
  FETCH_OUTFIT = "FETCH_OUTFIT",
  CREATE_OUTFIT = "CREATE_OUTFIT",
  UPDATE_OUTFIT = "UPDATE_OUTFIT",
  STATE_OUTFIT = "STATE_OUTFIT",
  POSITION_OUTFIT = "POSITION_OUTFIT",
  DELETE_OUTFIT = "DELETE_OUTFIT",
  //* loading...
  START_OUTFITS = "START_OUTFITS",
  END_OUTFITS = "END_OUTFITS",
  START_OUTFIT = "START_OUTFIT",
  END_OUTFIT = "END_OUTFIT",
  START_OUTFIT_UPDATE = "START_OUTFIT_UPDATE",
  END_OUTFIT_UPDATE = "END_OUTFIT_UPDATE",
  START_OUTFIT_DELETE = "START_OUTFIT_DELETE",
  END_OUTFIT_DELETE = "END_OUTFIT_DELETE",
  //* Ref orders...
  SEARCH_ORDER_OUTFITS = "SEARCH_ORDER_OUTFITS",
  FETCH_ORDER_OUTFITS = "FETCH_ORDER_OUTFITS",
  FETCH_ORDER_SELECTED_OUTFITS = "FETCH_ORDER_SELECTED_OUTFITS",
  //* errors...
  ERROR_OUTFIT = "ERROR_OUTFIT",
  RESET_OUTFIT = "RESET_OUTFIT",

  //? orders...
  SEARCH_ORDERS = "SEARCH_ORDERS",
  FETCH_ORDERS = "FETCH_ORDERS",
  FETCH_DRIVER_ORDERS = "FETCH_DRIVER_ORDERS",
  FETCH_ORDER_STATUS = "FETCH_ORDER_STATUS",
  FETCH_ORDER_BRANDS_NOT_SHIPPED = "FETCH_ORDER_BRANDS_NOT_SHIPPED",
  FETCH_PRINT_ORDERS = "FETCH_PRINT_ORDERS",
  FETCH_ORDER = "FETCH_ORDER",
  CREATE_ORDER = "CREATE_ORDER",
  UPDATE_ORDER = "UPDATE_ORDER",
  STATE_ORDER = "STATE_ORDER",
  STATE_ORDERS = "STATE_ORDERS",
  DELETE_ORDER = "DELETE_ORDER",
  //* loading...
  START_ORDERS = "START_ORDERS",
  END_ORDERS = "END_ORDERS",
  START_ORDER = "START_ORDER",
  END_ORDER = "END_ORDER",
  START_ORDER_STATUS = "START_ORDER_STATUS",
  END_ORDER_STATUS = "END_ORDER_STATUS",
  START_ORDER_BRANDS_NOT_SHIPPED = "START_ORDER_BRANDS_NOT_SHIPPED",
  END_ORDER_BRANDS_NOT_SHIPPED = "END_ORDER_BRANDS_NOT_SHIPPED",
  START_PRINT_ORDERS = "START_PRINT_ORDERS",
  END_PRINT_ORDERS = "END_PRINT_ORDERS",
  ORDERS = "ORDERS",
  START_CUSTOMER_ORDERS = "START_CUSTOMER_ORDERS",
  END_CUSTOMER_ORDERS = "END_CUSTOMER_ORDERS",
  START_DRIVER_ORDERS = "START_DRIVER_ORDERS",
  END_DRIVER_ORDERS = "END_DRIVER_ORDERS",
  //* errors...
  ERROR_ORDER = "ERROR_ORDER",
  RESET_ORDER = "RESET_ORDER",

  //? order notes...
  FETCH_ORDER_NOTES = "FETCH_ORDER_NOTES",
  FETCH_ORDER_NOTE = "FETCH_ORDER_NOTE",
  CREATE_ORDER_NOTE = "CREATE_ORDER_NOTE",
  UPDATE_ORDER_NOTE = "UPDATE_ORDER_NOTE",
  STATE_ORDER_NOTE = "STATE_ORDER_NOTE",
  DELETE_ORDER_NOTE = "DELETE_ORDER_NOTE",
  START_GET_CART_URL = "START_GET_CART_URL",
  END_GET_CART_URL = "END_GET_CART_URL",
  //* loading...
  START_ORDER_NOTES = "START_ORDER_NOTES",
  END_ORDER_NOTES = "END_ORDER_NOTES",
  START_ORDER_NOTE = "START_ORDER_NOTE",
  END_ORDER_NOTE = "END_ORDER_NOTE",
  //* errors...
  ERROR_ORDER_NOTE = "ERROR_ORDER_NOTE",
  RESET_ORDER_NOTE = "RESET_ORDER_NOTE",

  //? order delivery notes...
  FETCH_ORDER_DELIVERY_NOTES = "FETCH_ORDER_DELIVERY_NOTES",
  FETCH_ORDER_DELIVERY_NOTE = "FETCH_ORDER_DELIVERY_NOTE",
  CREATE_ORDER_DELIVERY_NOTE = "CREATE_ORDER_DELIVERY_NOTE",
  UPDATE_ORDER_DELIVERY_NOTE = "UPDATE_ORDER_DELIVERY_NOTE",
  STATE_ORDER_DELIVERY_NOTE = "STATE_ORDER_DELIVERY_NOTE",
  DELETE_ORDER_DELIVERY_NOTE = "DELETE_ORDER_DELIVERY_NOTE",
  //* loading...
  START_ORDER_DELIVERY_NOTES = "START_ORDER_DELIVERY_NOTES",
  END_ORDER_DELIVERY_NOTES = "END_ORDER_DELIVERY_NOTES",
  START_ORDER_DELIVERY_NOTE = "START_ORDER_DELIVERY_NOTE",
  END_ORDER_DELIVERY_NOTE = "END_ORDER_DELIVERY_NOTE",
  //* errors...
  ERROR_ORDER_DELIVERY_NOTE = "ERROR_ORDER_DELIVERY_NOTE",
  RESET_ORDER_DELIVERY_NOTE = "RESET_ORDER_DELIVERY_NOTE",

  //? reports...
  FETCH_ORDERS_REPORT = "FETCH_ORDERS_REPORT",
  FETCH_COMPLETED_ORDERS_REPORT = "FETCH_COMPLETED_ORDERS_REPORT",
  FETCH_PROFIT_REPORT = "FETCH_PROFIT_REPORT",
  FETCH_USERS_REPORT = "FETCH_USERS_REPORT",
  //* loading...
  START_REPORTS = "START_REPORTS",
  END_REPORTS = "END_REPORTS",

  //? notifications...
  FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS",
  FETCH_MANAGEMENT_NOTIFICATIONS = "FETCH_MANAGEMENT_NOTIFICATIONS",
  FETCH_NOTIFICATION = "FETCH_NOTIFICATION",
  FETCH_MANAGEMENT_NOTIFICATION = "FETCH_MANAGEMENT_NOTIFICATION",
  CREATE_NOTIFICATION = "CREATE_NOTIFICATION",
  DELETE_NOTIFICATION = "DELETE_NOTIFICATION",
  READ_NOTIFICATION = "READ_NOTIFICATION",
  //* loading...
  START_NOTIFICATIONS = "START_NOTIFICATIONS",
  END_NOTIFICATIONS = "END_NOTIFICATIONS",
  START_MANAGEMENT_NOTIFICATIONS = "START_MANAGEMENT_NOTIFICATIONS",
  END_MANAGEMENT_NOTIFICATIONS = "END_MANAGEMENT_NOTIFICATIONS",
  //* errors...
  ERROR_NOTIFICATION = "ERROR_NOTIFICATION",
  RESET_NOTIFICATION = "RESET_NOTIFICATION",

  //? payment mthod discounts...
  FETCH_PAYMENT_METHOD_DISCOUNTS = "FETCH_PAYMENT_METHOD_DISCOUNTS",
  FETCH_PAYMENT_METHOD_DISCOUNT = "FETCH_PAYMENT_METHOD_DISCOUNT",
  CREATE_PAYMENT_METHOD_DISCOUNT = "CREATE_PAYMENT_METHOD_DISCOUNT",
  UPDATE_PAYMENT_METHOD_DISCOUNT = "UPDATE_PAYMENT_METHOD_DISCOUNT",
  STATE_PAYMENT_METHOD_DISCOUNT = "STATE_PAYMENT_METHOD_DISCOUNT",
  DELETE_PAYMENT_METHOD_DISCOUNT = "DELETE_PAYMENT_METHOD_DISCOUNT",
  //* loading...
  START_PAYMENT_METHOD_DISCOUNTS = "START_PAYMENT_METHOD_DISCOUNTS",
  END_PAYMENT_METHOD_DISCOUNTS = "END_PAYMENT_METHOD_DISCOUNTS",
  START_PAYMENT_METHOD_DISCOUNT = "START_PAYMENT_METHOD_DISCOUNT",
  END_PAYMENT_METHOD_DISCOUNT = "END_PAYMENT_METHOD_DISCOUNT",
  //* errors...
  ERROR_PAYMENT_METHOD_DISCOUNT = "ERROR_PAYMENT_METHOD_DISCOUNT",
  RESET_PAYMENT_METHOD_DISCOUNT = "RESET_PAYMENT_METHOD_DISCOUNT",

  //? discounts...
  FETCH_DISCOUNTS = "FETCH_DISCOUNTS",
  FETCH_DISCOUNT = "FETCH_DISCOUNT",
  CREATE_DISCOUNT = "CREATE_DISCOUNT",
  UPDATE_DISCOUNT = "UPDATE_DISCOUNT",
  STATE_DISCOUNT = "STATE_DISCOUNT",
  DELETE_DISCOUNT = "DELETE_DISCOUNT",
  //* loading...
  START_DISCOUNTS = "START_DISCOUNTS",
  END_DISCOUNTS = "END_DISCOUNTS",
  START_DISCOUNT = "START_DISCOUNT",
  END_DISCOUNT = "END_DISCOUNT",
  //* errors...
  ERROR_DISCOUNT = "ERROR_DISCOUNT",
  RESET_DISCOUNT = "RESET_DISCOUNT",

  //? promoCodes...
  FETCH_PROMOCODES = "FETCH_PROMOCODES",
  FETCH_PROMOCODE = "FETCH_PROMOCODE",
  CREATE_PROMOCODE = "CREATE_PROMOCODE",
  UPDATE_PROMOCODE = "UPDATE_PROMOCODE",
  STATE_PROMOCODE = "STATE_PROMOCODE",
  DELETE_PROMOCODE = "DELETE_PROMOCODE",
  VALID_PROMO_CODE = "VALID_PROMO_CODE",
  //* loading...
  START_PROMOCODES = "START_PROMOCODES",
  END_PROMOCODES = "END_PROMOCODES",
  START_PROMOCODE = "START_PROMOCODE",
  END_PROMOCODE = "END_PROMOCODE",
  //* errors...
  ERROR_PROMOCODE = "ERROR_PROMOCODE",
  RESET_PROMOCODE = "RESET_PROMOCODE",

  //? cartOffers...
  FETCH_CART_OFFERS = "FETCH_CART_OFFERS",
  FETCH_CART_OFFERS_ORDER = "FETCH_CART_OFFERS_ORDER",
  FETCH_CART_OFFER = "FETCH_CART_OFFER",
  CREATE_CART_OFFER = "CREATE_CART_OFFER",
  UPDATE_CART_OFFER = "UPDATE_CART_OFFER",
  STATE_CART_OFFER = "STATE_CART_OFFER",
  DELETE_CART_OFFER = "DELETE_CART_OFFER",
  //* loading...
  START_CART_OFFERS = "START_CART_OFFERS",
  END_CART_OFFERS = "END_CART_OFFERS",
  START_CART_OFFER = "START_CART_OFFER",
  END_CART_OFFER = "END_CART_OFFER",
  //* errors...
  ERROR_CART_OFFER = "ERROR_CART_OFFER",
  RESET_CART_OFFER = "RESET_CART_OFFER",

  //? Scraping items...
  FETCH_SCRAPING_ITEMS = "FETCH_SCRAPING_ITEMS",
  FETCH_SCRAPING_ITEM = "FETCH_SCRAPING_ITEM",
  FETCH_SCRAPING_ITEM_ORDER = "FETCH_SCRAPING_ITEM_ORDER",
  CREATE_SCRAPING_ITEM = "CREATE_SCRAPING_ITEM",
  UPDATE_SCRAPING_ITEM = "UPDATE_SCRAPING_ITEM",
  STATE_SCRAPING_ITEM = "STATE_SCRAPING_ITEM",
  DELETE_SCRAPING_ITEM = "DELETE_SCRAPING_ITEM",
  SEARCH_SCRAPING_ITEMS = "SEARCH_SCRAPING_ITEMS",
  //* loading...
  START_SCRAPING_ITEMS = "START_SCRAPING_ITEMS",
  END_SCRAPING_ITEMS = "END_SCRAPING_ITEMS",
  START_SCRAPING_ITEM = "START_SCRAPING_ITEM",
  END_SCRAPING_ITEM = "END_SCRAPING_ITEM",
  START_SEARCH_SCRAPING_ITEM = "START_SEARCH_SCRAPING_ITEM",
  END_SEARCH_SCRAPING_ITEM = "END_SEARCH_SCRAPING_ITEM",
  //* errors...
  ERROR_SCRAPING_ITEM = "ERROR_SCRAPING_ITEM",
  RESET_SCRAPING_ITEM = "RESET_SCRAPING_ITEM",

  //? addresses...
  FETCH_ADDRESSES = "FETCH_ADDRESSES",
  FETCH_ADDRESS = "FETCH_ADDRESS",
  CREATE_ADDRESS = "CREATE_ADDRESS",
  UPDATE_ADDRESS = "UPDATE_ADDRESS",
  DELETE_ADDRESS = "DELETE_ADDRESS",
  //* loading...
  START_ADDRESSES = "START_ADDRESSES",
  END_ADDRESSES = "END_ADDRESSES",
  START_ADDRESS = "START_ADDRESS",
  END_ADDRESS = "END_ADDRESS",
  //* errors...
  ERROR_ADDRESS = "ERROR_ADDRESS",
  RESET_ADDRESS = "RESET_ADDRESS",

  //? categories...
  SEARCH_CATEGORIES = "SEARCH_CATEGORIES",
  FETCH_CATEGORIES = "FETCH_CATEGORIES",
  FETCH_CATEGORY = "FETCH_CATEGORY",
  CREATE_CATEGORY = "CREATE_CATEGORY",
  UPDATE_CATEGORY = "UPDATE_CATEGORY",
  POSITION_CATEGORY = "POSITION_CATEGORY",
  STATE_CATEGORY = "STATE_CATEGORY",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  //* loading...
  START_CATEGORIES = "START_CATEGORIES",
  END_CATEGORIES = "END_CATEGORIES",
  START_CATEGORY = "START_CATEGORY",
  END_CATEGORY = "END_CATEGORY",
  //* errors...
  ERROR_CATEGORY = "ERROR_CATEGORY",
  RESET_CATEGORY = "RESET_CATEGORY",

  //? groups...
  SEARCH_GROUPS = "SEARCH_GROUPS",
  FETCH_GROUPS = "FETCH_GROUPS",
  FETCH_GROUPS_FEE = "FETCH_GROUPS_FEE",
  FETCH_GROUPS_EXCHANGE = "FETCH_GROUPS_EXCHANGE",
  FETCH_GROUPS_SHIPPING = "FETCH_GROUPS_SHIPPING",
  FETCH_GROUPS_FAST_SHIPPING = "FETCH_GROUPS_FAST_SHIPPING",
  FETCH_GROUPS_INSURANCE = "FETCH_GROUPS_INSURANCE",
  FETCH_GROUPS_EXCHANGE_REFUND = "FETCH_GROUPS_EXCHANGE_REFUND",
  FETCH_GROUPS_DELIVERY = "FETCH_GROUPS_DELIVERY",
  FETCH_GROUPS_TAX = "FETCH_GROUPS_TAX",
  FETCH_GROUP = "FETCH_GROUP",
  FETCH_TAX_GROUP = "FETCH_TAX_GROUP",
  FETCH_INSURANCE_GROUP = "FETCH_INSURANCE_GROUP",
  FETCH_DELIVERY_GROUP = "FETCH_DELIVERY_GROUP",
  FETCH_EXCHANGE_GROUP = "FETCH_EXCHANGE_GROUP",
  CREATE_GROUP = "CREATE_GROUP",
  UPDATE_GROUP = "UPDATE_GROUP",
  POSITION_GROUP = "POSITION_GROUP",
  STATE_GROUP = "STATE_GROUP",
  DELETE_GROUP = "DELETE_GROUP",
  //* loading...
  START_GROUPS = "START_GROUPS",
  END_GROUPS = "END_GROUPS",
  START_GROUP = "START_GROUP",
  END_GROUP = "END_GROUP",
  //* errors...
  ERROR_GROUP = "ERROR_GROUP",
  RESET_GROUP = "RESET_GROUP",

  //? transactions...
  FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS",
  FETCH_ADMIN_TRANSACTIONS = "FETCH_ADMIN_TRANSACTIONS",
  FETCH_AMOUNT_TRANSACTIONS = "FETCH_AMOUNT_TRANSACTIONS",
  FETCH_POINTS_TRANSACTIONS = "FETCH_POINTS_TRANSACTIONS",
  FETCH_USER_TRANSACTIONS = "FETCH_USER_TRANSACTIONS",
  FETCH_TRANSACTION = "FETCH_TRANSACTION",
  CREATE_TRANSACTION = "CREATE_TRANSACTION",
  UPDATE_TRANSACTION = "UPDATE_TRANSACTION",
  STATE_TRANSACTION = "STATE_TRANSACTION",
  DELETE_TRANSACTION = "DELETE_TRANSACTION",
  //* loading...
  START_TRANSACTIONS = "START_TRANSACTIONS",
  END_TRANSACTIONS = "END_TRANSACTIONS",
  START_TRANSACTION = "START_TRANSACTION",
  END_TRANSACTION = "END_TRANSACTION",

  //? feedbacks...
  FETCH_FEEDBACKS = "FETCH_FEEDBACKS",
  FETCH_FEEDBACK = "FETCH_FEEDBACK",
  DELETE_FEEDBACK = "DELETE_FEEDBACK",
  //* loading...
  START_FEEDBACKS = "START_FEEDBACKS",
  END_FEEDBACKS = "END_FEEDBACKS",

  //? settings...
  FETCH_SETTINGS = "FETCH_SETTINGS",
  UPDATE_SUPPORT = "UPDATE_SUPPORT",
  //* loading...
  START_SETTINGS = "START_SETTINGS",
  END_SETTINGS = "END_SETTINGS",
  START_UPDATE_SUPPORT = "START_UPDATE_SUPPORT",
  END_UPDATE_SUPPORT = "END_UPDATE_SUPPORT",
  //* Store...
  FETCH_STORE = "FETCH_STORE",
  UPDATE_STORE = "UPDATE_STORE",
  //* loading...
  START_STORE = "START_STORE",
  END_STORE = "END_STORE",
  //* errors...
  ERROR_STORE = "ERROR_STORE",
  RESET_STORE = "RESET_STORE",
  //* Store Settings...
  FETCH_STORE_SETTINGS = "FETCH_STORE_SETTINGS",
  UPDATE_STORE_SETTINGS = "UPDATE_STORE_SETTINGS",
  //* loading...
  START_STORE_SETTINGS = "START_STORE_SETTINGS",
  END_STORE_SETTINGS = "END_STORE_SETTINGS",
  //* Security Settings...
  //* loading...
  START_PASSWORD = "START_PASSWORD",
  END_PASSWORD = "END_PASSWORD",
  //* Profile Settings...
  //* loading...
  START_PROFILE = "START_PROFILE",
  END_PROFILE = "END_PROFILE",
  UPDATE_PROFILE = "UPDATE_PROFILE",

  //? payment methods...
  FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS",
  FETCH_PAYMENT_METHOD = "FETCH_PAYMENT_METHOD",
  CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD",
  UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD",
  STATE_PAYMENT_METHOD = "STATE_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  //* loading...
  START_PAYMENT_METHODS = "START_PAYMENT_METHODS",
  END_PAYMENT_METHODS = "END_PAYMENT_METHODS",
  START_PAYMENT_METHOD = "START_PAYMENT_METHOD",
  END_PAYMENT_METHOD = "END_PAYMENT_METHOD",
  //* errors...
  ERROR_PAYMENT_METHOD = "ERROR_PAYMENT_METHOD",
  RESET_PAYMENT_METHOD = "RESET_PAYMENT_METHOD",

  //? errors...
  AUTH_ERROR = "AUTH_ERROR",
  ACCOUNT_ERROR = "ACCOUNT_ERROR",

  //* loading...

  // main loading.
  START_LOADING = "START_LOADING",
  END_LOADING = "END_LOADING",

  // main loading.
  START_HOME = "START_HOME",
  END_HOME = "END_HOME",

  // search loading.
  START_SEARCH = "START_SEARCH",
  END_SEARCH = "END_SEARCH",

  // Errors...
  MAIN_ERROR = "MAIN_ERROR",
}
