import React from "react";
import Logo from "../../Constants/Logo";

const LoadingSuspense = () => {
  return (
    <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: "20px"}}>
        <img style={{height:150}} src={Logo()} alt='Akkooo app logo'/>
    </div>
  )
}
export default LoadingSuspense;