
export const initialDiscount = {
    paymentMethod: String() || null,
    scrapingItems: [String()],
    discount: Number() || null,
    type: String() || null,
    discountTarget: String() || null,
  }
  export type initialDiscountType = typeof initialDiscount;
  
  export const initialDiscountErrors = { paymentMethod: String() || null, scrapingItems: String() || null, discount: String() || null, type: String() || null, discountTarget: String() || null}
  export type initialDiscountErrorsType = typeof initialDiscountErrors;

  export const discountTypes = [
    {label: "None", value: null},
    {label: "Percent", value: "Percent"},
    {label: "Price", value: "Price"},
  ];
  
  export const discountTargetTypes = [
    {label: "None", value: null},
    {label: "Shipping", value: "Shipping"},
    {label: "Delivery", value: "Delivery"},
    {label: "Total", value: "Total"},
  ];
  
  export const OfferStatusList = [
    {label: "Active", value: "Active"},
    {label: "Disabled", value: "Disabled"},
  ];

  export const discountsTableHead = [
    {
        id: 'paymentMethod',
        numeric: true,
        label: 'Payment Method',
      },
      {
        id: 'discount',
        numeric: true,
        label: 'Discount',
      },
      {
        id: 'discountTarget',
        numeric: true,
        label: 'Target',
      },
      {
        id: 'state',
        numeric: true,
        label: 'Status',
      },
      {
        id: 'createdAt',
        numeric: true,
        label: 'CreatedAt',
      },
      {
        id: '',
        numeric: false,
        label: '',
      },
];