import AdminType from "../Interfaces/admin-interface";

export type objects = "Home" | "Setting" | "Wallet" | "Address" | "Device" | "ScrapingOrder" | "OrderNote" | "OrderDeliveryNote" | "ScrapingCart" | "Advertisement" | "User" | "MainSectionCategory" | "Group" | "MainSection" | "ScrapingItem" | "Product" | "Outfit" | "Transaction" | "DriverTransactions" | "Report" | "Offer" | "PaymentMethod" | "PaymentMethodDiscount" | "Notification" | "PromoCode" | "CartOffer" | "Discount" | "SocialLink" | "Driver" | "Admin" | "Team" | "all";

export enum objectsEnum { "Home", "Setting", "Wallet", "Address", "Device", "ScrapingOrder", "OrderNote", "OrderDeliveryNote", "ScrapingCart", "Advertisement", "User", "MainSectionCategory", "Group", "MainSection", "ScrapingItem", "Product", "Outfit", "Transaction", "Report", "Offers", "PaymentMethod", "PaymentMethodDiscount", "Notification", "PromoCode", "CartOffer", "Discount", "SocialLink", "Driver", "Admin", "Team", "all" };

export enum abilitiesEnum { "Read", "Create", "Update", "Delete" };

export const initialAbilities = ["read", "create", "update", "delete"];

  export const permissionsList = [
    {label: "Home", value: "Home"},
    {label: "Scraping Orders", value: "ScrapingOrder"},
    {label: "Order Notes", value: "OrderNote"},
    {label: "Order Delivery Notes", value: "OrderDeliveryNote"},
    {label: "Scraping Cart", value: "ScrapingCart"},
    {label: "Advertisements", value: "Advertisement"},
    {label: "Customers", value: "User"},
    {label: "Main Section Categories", value: "MainSectionCategory"},
    {label: "Groups", value: "Group"},
    {label: "Main Sections", value: "MainSection"},
    {label: "Scraping Item", value: "ScrapingItem"},
    {label: "Product", value: "Porduct"},
    {label: "Outfit", value: "Outfit"},
    {label: "Transactions", value: "Transaction"},
    // {label: "Reports", value: "Report"},
    {label: "Payment Methods", value: "PaymentMethod"},
    {label: "Payment Method Discounts", value: "PaymentMethodDiscount"},
    {label: "Promo Codes", value: "PromoCode"},
    {label: "Cart Offers", value: "CartOffer"},
    {label: "Discounts", value: "Discount"},
    {label: "Notifications", value: "Notification"},
    {label: "Social Links", value: "SocialLink"},
    {label: "Drivers", value: "Driver"},
    {label: "Admins", value: "Admin"},
    {label: "Team", value: "Team"},
    // {label: "Driver Transactions", value: "DriverTransactions"},
    // {label: "Wallets", value: "Wallet"},
    // {label: "Addresses", value: "Address"},
    // {label: "Settings", value: "Setting"},
  ];

export const initialPermission = {
    object: permissionsList[0].value, abilities: ["Read"]
}
 
export type initialPermissiontype = typeof initialPermission;

export const initialErrors = { 
    firstName: String() || null,
    lastName: String() || null,
    phoneNumber: String() || null,
    email: String() || null,
    password: String() || null,
    permissions: String() || null,
}
  
  export const initialAdmin = {
    firstName: String(),
    lastName: String(),
    phoneNumber: String(),
    email: String(),
    password: String(),
    permissions: [initialPermission] || null
  };
  
  export type initialAdminType = typeof initialAdmin;
  export type initialErrorsType = typeof initialErrors;
  export type initialPermissionsType = typeof initialPermission[];

  export const initialPermissions: AdminType['initialPermission'][] = [
    {object: "Home", abilities: []},
    {object: "ScrapingOrder", abilities: []},
    {object: "Advertisement", abilities: []},
    {object: "User", abilities: []},
    {object: "MainSectionCategory", abilities: []},
    {object: "Group", abilities: []},
    {object: "MainSection", abilities: []},
    {object: "ScrapingItem", abilities: []},
    {object: "Product", abilities: []},
    {object: "Outfit", abilities: []},
    {object: "Transaction", abilities: []},
    {object: "Report", abilities: []},
    {object: "PaymentMethod", abilities: []},
    {object: "Notification", abilities: []},
    {object: "PromoCode", abilities: []},
    {object: "Discount", abilities: []},
    {object: "SocialLink", abilities: []},
    {object: "Driver", abilities: []},
    {object: "Admin", abilities: []},
    {object: "Wallet", abilities: []},
    {object: "Address", abilities: []},
    {object: "Device", abilities: []},
    {object: "Setting", abilities: []},
  ];
  
  export const adminsTableHead = [
      {
        id: 'name',
        numeric: true,
        label: 'Name',
      },
      {
        id: 'phoneNumber',
        numeric: true,
        label: 'Phone Number',
      },
      {
        id: 'location',
        numeric: true,
        label: 'Location',
      },
      {
        id: 'state',
        numeric: true,
        label: 'Status',
      },
      {
        id: 'createdAt',
        numeric: true,
        label: 'CreatedAt',
      },
      {
        id: '',
        numeric: false,
        label: '',
      },
    ];