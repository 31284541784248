import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import ContextTypes from "../Interfaces/context.interface";
import { SelectorState } from "../Selectors/index.selector";
import * as authActions from '../Actions/Auth.action'
import { bindActionCreators } from "redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { initialPermissions } from "../Constants/userPermission";
import { useLocalStorage } from "../Hooks/useLocalStorage";

export const useAuth = () => useContext(AuthContext);

export const AuthContext = createContext({
  info: {
    _id: '',
    email: '',
    image: '',
    firstName: '',
    lastName: '',
    utilities: {
      country: '',
      city: '',
      currency: '',
      language: ''
    },
    store: {
      name: '',
      username: '',
      image: '',
      primaryColor: ''
    },
    type: '',
    permissions: initialPermissions
  },
  linfo: {} as boolean,
  offline: false
});

export const AuthProvider = ({children} :ContextTypes["childrenProps"]) => {
  const dispatch = useDispatch();
  const navigate :NavigateFunction = useNavigate();
  const { info, linfo } = useSelector((state :SelectorState) => state.Auth);
  const { getInfo, getInfoDriver } = bindActionCreators(authActions, dispatch);
  const [offline, setOffline] = useState(false);
  const driver = localStorage.getItem("typename");

  useEffect(() => {
    if(!info && !linfo){
      if(driver && driver === "Driver") {
        getInfoDriver(navigate);
      } else {
        getInfo(navigate);
      }
    }
  },[info]);

  useEffect(()=> {
    if(!navigator.onLine){
      setOffline(true);
    } else {
      setOffline(false)
    }
  },[navigator.onLine])
  
    return (
      <AuthContext.Provider value={{info, linfo, offline}}>
          {children}
      </AuthContext.Provider>
    );
  }