import { initialErrors } from "../Constants/Payment-methods.constants";
import { Types } from "../Constants/Types";
import PaymentMethodType from "../Interfaces/payment-methods.interface";

const PaymentMethodsReducer = (state: PaymentMethodType['status'] = { 
        paymentMethod: undefined,
        paymentMethods: [],
        lpaymentMethods: false,
        lpaymentMethod: false,
        error: initialErrors
    },
    action: PaymentMethodType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_PAYMENT_METHODS:
            return {...state, paymentMethods: action.payload};

        case Types.FETCH_PAYMENT_METHOD:
            return {...state, paymentMethod: action.payload};

        case Types.CREATE_PAYMENT_METHOD:
            return {...state, paymentMethods: [action.payload, ...state.paymentMethods]};

        case Types.UPDATE_PAYMENT_METHOD: 
            return {...state, paymentMethods: state.paymentMethods.map(paymentMethod => paymentMethod._id === action.payload.id ? action.payload : paymentMethod)};
        
        case Types.STATE_PAYMENT_METHOD: 
            return {...state, paymentMethods: state.paymentMethods.map(paymentMethod => paymentMethod._id === action.payload.id ? {...paymentMethod, ...action.payload} : paymentMethod), paymentMethod: {...state.paymentMethod, state: action.payload.state}};

        case Types.DELETE_PAYMENT_METHOD: 
            return {...state, paymentMethods: state.paymentMethods.filter(paymentMethod => paymentMethod._id !== action.payload)}

        case Types.START_PAYMENT_METHODS:
            return {...state, lpaymentMethods: true};

        case Types.END_PAYMENT_METHODS:
            return {...state, lpaymentMethods: false};

        case Types.ERROR_PAYMENT_METHOD:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_PAYMENT_METHOD:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default PaymentMethodsReducer;