import { initialPromoCodeErrors } from "../Constants/Offers.constants";
import { Types } from "../Constants/Types";
import PromoCodeType from "../Interfaces/promo-codes.interface";

const PromoCodesReducer = (state: PromoCodeType['status'] = { 
        promoCode: undefined,
        validPromoCode: undefined,
        promoCodes: [],
        lpromoCodes: false,
        lpromoCode: false,
        pages: 0,
        error: initialPromoCodeErrors
    },
    action: PromoCodeType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_PROMOCODES:
            return {...state, promoCodes: action.payload, pages: action.pages};

        case Types.FETCH_PROMOCODE:
            return {...state, promoCode: action.payload};

        case Types.VALID_PROMO_CODE:
            return {...state, validPromoCode: action.payload};

        case Types.CREATE_PROMOCODE:
            return {...state, promoCodes: [action.payload, ...state.promoCodes]};

        case Types.UPDATE_PROMOCODE: 
            return {...state, promoCodes: state.promoCodes.map(promoCode => promoCode._id === action.payload.id ? action.payload : promoCode)};
        
        case Types.STATE_PROMOCODE: 
            return {...state, promoCodes: state.promoCodes.map(promoCode => promoCode._id === action.payload.id ? {...promoCode, ...action.payload} : promoCode), promoCode: {...state.promoCode, state: action.payload.state}};

        case Types.DELETE_PROMOCODE: 
            return {...state, promoCodes: state.promoCodes.filter(promoCode => promoCode._id !== action.payload)}

        case Types.START_PROMOCODES:
            return {...state, lpromoCodes: true};

        case Types.END_PROMOCODES:
            return {...state, lpromoCodes: false};

        case Types.ERROR_PROMOCODE:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_PROMOCODE:
            return {...state, error: initialPromoCodeErrors}

        default: 
            return state
    }
}

export default PromoCodesReducer;