import { initialErrors } from "../Constants/Categories.constants";
import { Types } from "../Constants/Types";
import GroupType from "../Interfaces/group.interface";

const GroupsReducer = (state: GroupType['status'] = { 
        group: undefined,
        groups: [],
        feeGroups: [],
        exchangeGroups: [],
        shippingGroups: [],
        fastShippingGroups: [],
        insuranceGroups: [],
        exchangeRefundGroups: [],
        deliveryGroups: [],
        taxGroups: [],
        results: [],
        taxGroup: undefined,
        insuranceGroup: undefined,
        deliveryGroup: undefined,
        exchangeGroup: undefined,
        lgroups: false,
        pages: 0,
        resultPages: 0,
        error: initialErrors
    },
    action: GroupType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_GROUPS:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_GROUPS:
            return {...state, groups: action.payload, pages: action.pages};

        case Types.FETCH_GROUPS_FEE:
            return {...state, feeGroups: action.payload, pages: action.pages};

        case Types.FETCH_GROUPS_EXCHANGE:
            return {...state, exchangeGroups: action.payload, pages: action.pages};

        case Types.FETCH_GROUPS_SHIPPING:
            return {...state, shippingGroups: action.payload, pages: action.pages};

        case Types.FETCH_GROUPS_FAST_SHIPPING:
            return {...state, fastShippingGroups: action.payload, pages: action.pages};

        case Types.FETCH_GROUPS_INSURANCE:
            return {...state, insuranceGroups: action.payload, pages: action.pages};

        case Types.FETCH_GROUPS_EXCHANGE_REFUND:
            return {...state, exchangeRefundGroups: action.payload, pages: action.pages};

        case Types.FETCH_GROUPS_DELIVERY:
            return {...state, deliveryGroups: action.payload, pages: action.pages};

        case Types.FETCH_GROUPS_TAX:
            return {...state, taxGroups: action.payload, pages: action.pages};

        case Types.FETCH_GROUP:
            return {...state, group: action.payload};

        case Types.CREATE_GROUP:
            return {...state, groups: [action.payload, ...state.groups]};

        case Types.UPDATE_GROUP: 
            return {...state, groups: state.groups.map(group => group._id === action.payload.id ? action.payload : group)};
        
        case Types.STATE_GROUP: 
            return {...state, groups: state.groups.map(group => group._id === action.payload.id ? {...group, ...action.payload} : group), group: {...state.group, state: action.payload.state}};

        case Types.DELETE_GROUP: 
            return {...state, groups: state.groups.filter(group => group._id !== action.payload)}

        case Types.FETCH_TAX_GROUP:
            return {...state, taxGroup: action.payload};

        case Types.FETCH_INSURANCE_GROUP:
            return {...state, insuranceGroup: action.payload};

        case Types.FETCH_DELIVERY_GROUP:
            return {...state, deliveryGroup: action.payload};

        case Types.FETCH_EXCHANGE_GROUP:
            return {...state, exchangeGroup: action.payload};

        case Types.START_GROUPS:
            return {...state, lgroups: true};

        case Types.END_GROUPS:
            return {...state, lgroups: false};

        case Types.ERROR_GROUP:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_GROUP:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default GroupsReducer;