
export const initialStore = {
    name: String(),
    username: String(),
    category: String(),
    primaryColor: String(),
    description: String() || null,
    utilities: {
        buget: String(),
        paymentMethods: [String()],
        paymentTax: String(),
    },
    preferences: {
        country: String(),
        city: String(),
        currency: String(),
        language: String()
    }
}

export type initialStoreType = typeof initialStore;


export const initialStoreErrors = {
    image: String() || null,
    name: String() || null,
    username: String() || null,
    category: String() || null,
    primaryColor: String() || null,
    buget: String() || null,
    paymentMethods: String() || null,
    paymentTax: String() || null,
    country: String() || null,
    city: String() || null,
    currency: String() || null,
    language: String() || null
};

export type initialStoreErrorsType = typeof initialStoreErrors;

export const paymentMethodsList = ["Credit Card", "Apple Pay", "Google Pay", "STC Pay", "Mada", "Mezza", "Paypal", "Tabby"];

export const paymentTaxList = [
    {label: "2.5% on Merchant ( you )", value: "Merchant"},
    {label: "2.5% on Client ( user )", value: "Client"},
    {label: "1.25% on Merchant & 1.25% on Client ( Best Option )", value: "Merchant & Client"}
];

export const bugetList = [
    {label: "form 1000$ to 2000$", value: "buget1"},
    {label: "form 2000$ to 3000$", value: "buget2"},
    {label: "form 3000$ to 4000$", value: "buget3"},
    {label: "form 4000$ to 5000$", value: "buget4"},
];

export const categoryList = [
    {label: "Clothes", value: "Clothes"},
    {label: "E-Services", value: "E-Services"},
    {label: "Beauty", value: "Beauty"},
    {label: "Accessories", value: "Accessories"},
];