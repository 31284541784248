
export const initialErrors = { 
  image: String() || null,
  title: String() || null,
  mainSection: String() || null
};
export type initialErrorsType = typeof initialErrors;

export const initialCategory = {
  title: {
      main: "",
      en: String() || null,
      kr: String() || null
  },
  mainSection: String() || null
};

export const initialUpdateCategory = {
  id: "",
  title: {
      main: "",
      en: String() || null,
      kr: String() || null
  },
  mainSection: String() || null
};

export const categoryStatusList = [
  {label: "Active", value: "Active"},
  {label: "Disabled", value: "Disabled"}
];

export const categoriesTableHead = [
    {
      id: 'title',
      numeric: true,
      label: 'Title',
    },
    {
      id: 'target',
      numeric: true,
      label: 'Target',
    },
    {
      id: 'position',
      numeric: true,
      label: 'Position',
    },
    {
      id: 'state',
      numeric: true,
      label: 'status',
    },
    {
      id: 'createdAt',
      numeric: true,
      label: 'CreatedAt',
    },
    {
      id: '',
      numeric: false,
      label: '',
    },
  ];