import { initialErrors } from "../Constants/Ads.constants";
import { Types } from "../Constants/Types";
import AdvertisementType from "../Interfaces/advertisements.interface";

const AdvertisementsReducer = (state: AdvertisementType['status'] = { 
        advertisement: undefined,
        advertisements: [],
        results: [],
        ladvertisements: false,
        pages: 0,
        resultPages: 0,
        error: initialErrors
    },
    action: AdvertisementType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_ADVERTISEMENTS:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_ADVERTISEMENTS:
            return {...state, advertisements: action.payload, pages: action.pages};

        case Types.FETCH_ADVERTISEMENT:
            return {...state, advertisement: action.payload};

        case Types.CREATE_ADVERTISEMENT:
            return {...state, advertisements: [action.payload, ...state.advertisements]};

        case Types.UPDATE_ADVERTISEMENT: 
            return {...state, advertisements: state.advertisements.map(advertisement => advertisement._id === action.payload.id ? action.payload : advertisement)};
        
        case Types.STATE_ADVERTISEMENT: 
            return {...state, advertisements: state.advertisements.map(advertisement => advertisement._id === action.payload.id ? {...advertisement, ...action.payload} : advertisement), advertisement: {...state.advertisement, state: action.payload.state}};

        case Types.DELETE_ADVERTISEMENT: 
            return {...state, advertisements: state.advertisements.filter(advertisement => advertisement._id !== action.payload)}

        case Types.START_ADVERTISEMENTS:
            return {...state, ladvertisements: true};

        case Types.END_ADVERTISEMENTS:
            return {...state, ladvertisements: false};

        case Types.ERROR_ADVERTISEMENT:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_ADVERTISEMENT:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default AdvertisementsReducer;