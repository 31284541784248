import { initialErrors } from "../Constants/StoreSection.constants";
import { Types } from "../Constants/Types";
import MainSectionType from "../Interfaces/main-sections.interface";

const StoreSectionsReducer = (state: MainSectionType['status'] = { 
        mainSection: undefined,
        mainSections: [],
        results: [],
        lmainSections: false,
        lmainSection: false,
        pages: 0,
        resultPages: 0,
        error: ''
    },
    action: MainSectionType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_MAIN_SECTIONS:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_MAIN_SECTIONS:
            return {...state, mainSections: action.payload, pages: action.pages};

        case Types.FETCH_MAIN_SECTION:
            return {...state, mainSection: action.payload};

        case Types.CREATE_MAIN_SECTION:
            return {...state, mainSections: [action.payload, ...state.mainSections]};

        case Types.UPDATE_MAIN_SECTION: 
            return {...state, mainSections: state.mainSections.map(mainSection => mainSection._id === action.payload.id ? action.payload : mainSection)};
        
        case Types.STATE_MAIN_SECTION: 
            return {...state, mainSections: state.mainSections.map(mainSection => mainSection._id === action.payload.id ? {...mainSection, ...action.payload} : mainSection), mainSection: {...state.mainSection, state: action.payload.state}};

        case Types.DELETE_MAIN_SECTION: 
            return {...state, mainSections: state.mainSections.filter(mainSection => mainSection._id !== action.payload)}

        case Types.START_MAIN_SECTIONS:
            return {...state, lmainSections: true};

        case Types.END_MAIN_SECTIONS:
            return {...state, lmainSections: false};

        // case Types.ERROR_MAIN_SECTION:
        //     return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_MAIN_SECTION:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default StoreSectionsReducer;