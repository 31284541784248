export const initialErrors = { 
    note: String() || null
  };
  export type initialErrorsType = typeof initialErrors;
  
  export const initialNote = {
    note: String() || null,
  };
  export type initialNoteType = typeof initialNote;

  export const initialUpdateNote = {
    note: String() || null,
  };