import { initialDiscountErrors } from "../Constants/Payment-method-discounts.constants";
import { Types } from "../Constants/Types";
import PaymentMethodDiscountType from "../Interfaces/payment-method-discounts.interface";

const PaymentMethodDiscountsReducer = (state: PaymentMethodDiscountType['status'] = { 
        paymentMethodDiscount: undefined,
        paymentMethodDiscounts: [],
        lpaymentMethodDiscounts: false,
        lpaymentMethodDiscount: false,
        pages: 0,
        error: initialDiscountErrors
    },
    action: PaymentMethodDiscountType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_PAYMENT_METHOD_DISCOUNTS:
            return {...state, paymentMethodDiscounts: action.payload, pages: action.pages};

        case Types.FETCH_PAYMENT_METHOD_DISCOUNT:
            return {...state, paymentMethodDiscount: action.payload};

        case Types.CREATE_PAYMENT_METHOD_DISCOUNT:
            return {...state, paymentMethodDiscounts: [action.payload, ...state.paymentMethodDiscounts]};

        case Types.UPDATE_PAYMENT_METHOD_DISCOUNT: 
            return {...state, paymentMethodDiscounts: state.paymentMethodDiscounts.map(paymentMethodDiscount => paymentMethodDiscount._id === action.payload.id ? action.payload : paymentMethodDiscount)};
        
        case Types.STATE_PAYMENT_METHOD_DISCOUNT: 
            return {...state, paymentMethodDiscounts: state.paymentMethodDiscounts.map(paymentMethodDiscount => paymentMethodDiscount._id === action.payload.id ? {...paymentMethodDiscount, ...action.payload} : paymentMethodDiscount), paymentMethodDiscount: {...state.paymentMethodDiscount, state: action.payload.state}};

        case Types.DELETE_PAYMENT_METHOD_DISCOUNT: 
            return {...state, paymentMethodDiscounts: state.paymentMethodDiscounts.filter(paymentMethodDiscount => paymentMethodDiscount._id !== action.payload)}

        case Types.START_PAYMENT_METHOD_DISCOUNTS:
            return {...state, ldiscounts: true};

        case Types.END_PAYMENT_METHOD_DISCOUNTS:
            return {...state, ldiscounts: false};

        case Types.ERROR_PAYMENT_METHOD_DISCOUNT:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_PAYMENT_METHOD_DISCOUNT:
            return {...state, error: initialDiscountErrors}

        default: 
            return state
    }
}

export default PaymentMethodDiscountsReducer;