import { ApolloClient, InMemoryCache} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client'

const uri = process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_GRAPHQL_URI : process.env.REACT_APP_NODE_ENV === "development" ? process.env.REACT_APP_GRAPHQL_URI_DEV : process.env.REACT_APP_GRAPHQL_URI_LOCAL;

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: uri, credentials: 'include',
        headers: {'Apollo-Require-Preflight': 'true'},
        fetch: (uri, options) => {
            return new Promise((resolve, reject) => {
              const timeoutId = setTimeout(() => reject(new Error('Request timeout')), 300000); // 5 minutes
              fetch(uri, options).then(
                response => {
                  clearTimeout(timeoutId);
                  resolve(response);
                },
                err => {
                  clearTimeout(timeoutId);
                  reject(err);
                }
              );
            })
        }
    }),
    credentials: 'include',
    headers: {'Apollo-Require-Preflight': 'true'}
});