
export const initialDiscount = {
  scrapingItem: String(),
  discount: Number(),
  type: String(),
  limit: Number(),
  expire: new Date()
}
export type initialDiscountType = typeof initialDiscount;

export const initialDiscountErrors = { scrapingItem: String() || null, discount: String() || null, type: String() || null, limit: String() || null, expire: String() || null}
export type initialDiscountErrorsType = typeof initialDiscountErrors;

export const initialPromoCode = {
  name: String(),
  user: String() || null,
  type: String(),
  discountTarget: String(),
  discount: Number(),
  limit: Number(),
  expire: new Date()
}
export type initialPromoCodeType = typeof initialPromoCode;

export const initialPromoCodeErrors = {customer: String() || null, name: String() || null, discount: String() || null, type: String() || null, discountTarget: String() || null, limit: String() || null, expire: String() || null}
export type initialPromoCodeErrorsType = typeof initialPromoCodeErrors;

export const discountTypes = [
  {label: "None", value: null},
  {label: "Percent", value: "Percent"},
  {label: "Price", value: "Price"},
];

export const discountTargetTypes = [
  {label: "None", value: null},
  {label: "Shipping", value: "Shipping"},
  {label: "Delivery", value: "Delivery"},
  {label: "Shipping & Delivery", value: "Shipping & Delivery"},
];

export const OfferStatusList = [
  {label: "Active", value: "Active"},
  {label: "Disabled", value: "Disabled"},
  {label: "Expired", value: "Expired", disabled: true},
];

export const discountsTableHead = [
    {
        id: 'scrapingItem',
        numeric: true,
        label: 'Scraping Item',
      },
      {
        id: 'discount',
        numeric: true,
        label: 'Discount',
      },
      {
        id: 'expire',
        numeric: true,
        label: 'Expire',
      },
      {
        id: 'state',
        numeric: true,
        label: 'Status',
      },
      {
        id: 'createdAt',
        numeric: true,
        label: 'CreatedAt',
      },
      {
        id: '',
        numeric: false,
        label: '',
      },
];

export const promoCodesTableHead = [
    {
        id: 'name',
        numeric: true,
        label: 'Promo Code',
      },
      {
        id: 'discount',
        numeric: true,
        label: 'Discount',
      },
      {
        id: 'expire',
        numeric: true,
        label: 'Expire',
      },
      {
        id: 'user',
        numeric: true,
        label: 'Customer',
      },
      {
        id: 'state',
        numeric: true,
        label: 'Status',
      },
      {
        id: 'createdAt',
        numeric: true,
        label: 'CreatedAt',
      },
      {
        id: '',
        numeric: false,
        label: '',
      },
]