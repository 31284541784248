
import { Types } from '../Constants/Types';
import HomeTypes from '../Interfaces/home-interface';

const HomeReducer = (state :HomeTypes['status']= {
    orders: 0,
    status: { Completed: 0, Accepted: 0, Pending: 0, InDelivery: 0, Canceled: 0 },
    recentlyOrders: [],
    week: { d0: 0, d1: 0, d2: 0, d3: 0, d4: 0, d5: 0, d6: 0 },
    saling: [],
    sale: 0,
    revenue: 0,
    carts: 0,
    customers: 0,
    completed: 0,
    brands: [],
    rating: 0.0,
    totalRates: 0,
    lhome: false
    }, action : HomeTypes['action']) => {

    switch (action.type) {
        case Types.START_HOME:
            return {...state, lhome: true};

        case Types.END_HOME: 
            return {...state, lhome: false};

        case Types.FETCH_HOME: 
            return{...state, ...action.payload}

        default:
            return state
    }
}
export default HomeReducer;