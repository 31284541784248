
export const initialUserAddressError = { 
    _id: String() || null,
    title: String() || null,
    fullName: String() || null,
    country: String() || null,
    province: String() || null,
    city: String() || null,
    address: String() || null,
    building: String() || null,
    apartment: String() || null,
    phoneNumber: String() || null,
    alternativePhoneNumber: String() || null,
    description: String() || null,
    longitude: String() || null,
    latitude: String() || null,
};

export type InitialUserAddressError = typeof initialUserAddressError

export const initialUserAddress = {
    _id: String() || null,
    title: String() || null,
    fullName: String() || null,
    country: "Iraq",
    province: String() || null,
    city: String() || null,
    address: String() || null,
    building: String() || null,
    apartment: String() || null,
    phoneNumber: String() || null,
    alternativePhoneNumber: String() || null,
    description: String() || null,
    longitude: 0.0,
    latitude: 0.0,
}

export type InitialUserAddress = typeof initialUserAddress;