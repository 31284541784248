import OrderType from "../Interfaces/order-interface";
import { formatIQD } from "../Utilities/formatCurrenct";
import { Icons } from "./Icons";

export const initialErrors = {customer: String() || null, address: String() || null, products: String() || null};
export const initialProductErrors = {
    scrapingItem: String() || null,
    title: String() || null,
    url: String() || null,
    sku: String() || null,
    image: String() || null,
    color: String() || null,
    size: String() || null,
    price: String() || null,
    quantity: String() || null,
    currency: String() || null,
};

export const initialOrder = {};

export type initialOrderType = typeof initialOrder;
export type initialErrorsType = typeof initialErrors;
export type initialProductErrorsType = typeof initialProductErrors;



export const initialProduct = {
  id: String() || null,
  scrapingItem: String() || null,
  title: String() || null,
  url: String() || null,
  sku: String() || null,
  productId: String() || null,
  skuId: String() || null,
  skuCode: String() || null,
  image: String() || null,
  price: Number(),
  quantity: 1,
  // source: String() || null,
  currency: String() || null,
  canRefundAndExchage: String() || null,
  // color: String() || null,
  // size: String() || null,
  // colorName: String() || null,
  // colorImage: String() || null,
  variants: null
};
export type initialProductType = typeof initialProduct;
export const initialProducts = [initialProduct];
export type initialProductsType = typeof initialProducts;


export const initialProductPrice = {
  price: Number(),
  salePrice: Number(),
  currency: String(),
};

export const initialVenderPrice = {
  venderPrice: Number(),
  venderSalePrice: Number(),
  venderCurrency: String(),
};

export const InitialStore= {
  contactInfo: String() || null,
  countryCode: String() || null,
  id: String() || null,
  logo: String() || null,
  rating: String() || null,
  ref: String() || null,
  title: String() || null,
};

export  const discount = { id: String(), discount: Number(), type: String() };
export type initialDiscountType = typeof discount | null;
export const initialDiscount: initialDiscountType = discount as initialDiscountType;
export const initialProductData: OrderType["createOrderProductInput"] = {
  id: '',
  scrapingItem: String(),
  title: "",
  description: "",
  url: String(),
  productId: String(),
  sku: String(),
  skuCode: String(),
  skuId: String(),
  store: InitialStore || [],
  quantity: 1,
  brand: String() || null,
  source: String() || null,
  variants: null,
  productPrice: initialProductPrice,
  venderPrice: initialVenderPrice,
  venderSku: String() || null,
  image: null as unknown as File,
  stock: Number() || 1,
};
export type initialProductDataType = typeof initialProductData;


export const initialPaymentSummary = {
  subtotal: Number(),
  taxFee: Number(),
  shippingFee: Number(),
  deliveryFee: Number(),
  insuranceFee: Number(),
  orderTotal: Number(),
}

export type initialPaymentSummaryType = typeof initialPaymentSummary;

export const initialUser = {
  id: String() || null,
  firstName: String() || null,
  lastName: String() || null,
  phoneNumber: String() || null,
  email: String() || undefined,
  image: String() || undefined
};
export type initialUserType = typeof initialUser;


export const initialPromoCode = { name: String(), discountTarget: "Shipping & Delivery" || "Shipping" || "Delivery", discount: Number(), type: String() };
export type initialPromoCodeType = typeof initialPromoCode | null;
export const totalSummary = { total: 0, price: 0 };
export const totalDiscount = {totalPrice: 0, totalPercent: 0, totalPromoCode: 0};
export const initialGetAttributes = { initialColor: "", initialUnit: "", initialPrice: 0, initialColorIndex: 0 }

export const orderStatusList = [
  {label: "Pending", value: "Pending", color: "#ff9800"},
  {label: "Report", value: "Report", color: "#0FC2C0"},
  {label: "Await Payment", value: "Await Payment", color: "#0FC2C0"},
  // {label: "Await Payment State", value: "Await Payment State", color: "#0FC2C0"},
  // {label: "Add Product", value: "Add Product", color: "#4CAF50"},
  {label: "Await E-Pay", value: "Await Online Payment", color: "#0FC2C0"},
  {label: "Payment Processing", value: "Payment Processing", color: "#0FC2C0"},
  {label: "Paid", value: "Paid", color: "#13AE92", disabled: true, private: true},
  {label: "Fund Received", value: "Fund Received", color: "#13AE92"},
  {label: "Deposit Canceled", value: "Deposit Canceled", color: "#13AE92", disabled: true, private: true},

  {label: "Preparing", value: "Preparing", color: "#13AE92", private: true},
  {label: "Shein Preparing", value: "Shein Preparing", color: "#13AE92"},
  {label: "Turkey Preparing", value: "Turkey Preparing", color: "#13AE92"},
  {label: "Kuwait Preparing", value: "Kuwait Preparing", color: "#13AE92"},
  
  {label: "In Packaging", value: "In Packaging", color: "#13AE92"},

  {label: "Await Shipping", value: "Await Shipping", color: "#13AE92", private: true},
  {label: "Shein Await Shipping", value: "Shein Await Shipping", color: "#13AE92"},
  {label: "Turkey Await Shipping", value: "Turkey Await Shipping", color: "#13AE92"},
  {label: "Kuwait Await Shipping", value: "Kuwait Await Shipping", color: "#13AE92"},

  {label: "In Shipping", value: "In Shipping", color: "#13AE92", private: true},
  {label: "Shein In Shipping", value: "Shein In Shipping", color: "#13AE92"},
  {label: "Turkey In Shipping", value: "Turkey In Shipping", color: "#13AE92"},
  {label: "Kuwait In Shipping", value: "Kuwait In Shipping", color: "#13AE92"},

  {label: "In Dispatch", value: "In Dispatch", color: "#13AE92", private: false},
  // {label: "Shein In Dispatch", value: "Shein In Dispatch", color: "#13AE92"},
  // {label: "Turkey In Dispatch", value: "Turkey In Dispatch", color: "#13AE92"},
  // {label: "Kuwait In Dispatch", value: "Kuwait In Dispatch", color: "#13AE92"},

  {label: "In Delivery", value: "In Delivery", color: "#B24C6D"},
  {label: "At Office", value: "At Office", color: "#B24C6D"},

  {label: "Delivered", value: "Delivered", color: "#0099DD"},
  {label: "Completed", value: "Completed", color: "#0099DD"},
  {label: "Rejected", value: "Rejected", color: "#f44336"},
  {label: "Refunded", value: "Refunded", color: "#ec407a", private: true},
  {label: "Canceled", value: "Canceled", disabled: true, color: "#616161", private: true},
];

export const currency = [
  {label: "USD", value: "USD", image: true, src: `${process.env.PUBLIC_URL}/assets/countries/us.png`},
  {label: "TL", value: "TL", image: true, src: `${process.env.PUBLIC_URL}/assets/countries/tr.png`},
  {label: "IQD", value: "IQD", image: true, src: `${process.env.PUBLIC_URL}/assets/countries/iq.png`},
  {label: "KWD", value: "KWD", image: true, src: `${process.env.PUBLIC_URL}/assets/countries/uae.png`},
  {label: "AED", value: "AED", image: true, src: `${process.env.PUBLIC_URL}/assets/countries/uae.png`},
  {label: "None", value: undefined}
];

export const variantsTypes = [
  {label: "Color", value: "color"},
  {label: "Size", value: "size"},
  {label: "Option", value: "option"},
  {label: "Kind", value: "kind"},
  {label: "Package", value: "package"},
  {label: "None", value: undefined}
];

// export const orderStatusList = [
//   {label: "Pending", value: "Pending", disabled: false, color: "#ff9800"},
//   {label: "Accepted", value: "Accepted", color: "#13AE92"},
//   {label: "Await Payment", value: "Await Payment", color: "#A133FF"},
//   {label: "Paid", value: "Paid", disabled: true, color: "#0FC2C0"},
//   {label: "Fund Received", value: "Fund Received", disabled: true, color: "#DD4124"},

//   {label: "Preparing", value: "Preparing", color: "#3366FF", private: true},
//   {label: "Shein Preparing", value: "Shein Preparing", color: "#D5A133"},
//   {label: "Turkey Preparing", value: "Turkey Preparing", color: "#6B5B95"},
//   {label: "Kuwait Preparing", value: "Kuwait Preparing", color: "#92A8D1"},
  
//   {label: "In Packaging", value: "In Packaging", color: "#FF9A8B"},

//   {label: "Await Shipping", value: "Await Shipping", color: "#955251", private: true},
//   {label: "Shein Await Shipping", value: "Shein Await Shipping", color: "#D5A133"},
//   {label: "Turkey Await Shipping", value: "Turkey Await Shipping", color: "#6B5B95"},
//   {label: "Kuwait Await Shipping", value: "Kuwait Await Shipping", color: "#92A8D1"},

//   {label: "In Shipping", value: "In Shipping", color: "#FF6F61", private: true},
//   {label: "Shein In Shipping", value: "Shein In Shipping", color: "#D5A133"},
//   {label: "Turkey In Shipping", value: "Turkey In Shipping", color: "#6B5B95"},
//   {label: "Kuwait In Shipping", value: "Kuwait In Shipping", color: "#92A8D1"},

//   {label: "In Dispatch", value: "In Dispatch", color: "#33CCFF", private: true},
//   {label: "Shein In Dispatch", value: "Shein In Dispatch", color: "#D5A133"},
//   {label: "Turkey In Dispatch", value: "Turkey In Dispatch", color: "#6B5B95"},
//   {label: "Kuwait In Dispatch", value: "Kuwait In Dispatch", color: "#92A8D1"},

//   {label: "In Delivery", value: "In Delivery", color: "#C3447A"},

//   {label: "Completed", value: "Completed", color: "#0099DD"},
//   {label: "Rejected", value: "Rejected", color: "#f44336"},
//   {label: "Refunded", value: "Refunded", color: "#ec407a", private: true},
//   {label: "Canceled", value: "Canceled", disabled: true, color: "#616161", private: true},
// ];

export const orderPaymentList = [
  {value: "Cash", Icon: Icons.cash},
  {value: "Apple Pay", Icon: Icons.apple},
  {value: "Google Pay", Icon: Icons.google},
  {value: "PayPal", Icon: Icons.paypal},
  {value: "Credit Card", Icon: Icons.creditCard},
  {value: "BitCoin", Icon: Icons.bitcoin},
]

export const ordersTableHead = [
    {
      id: '_id',
      numeric: true,
      label: 'Order',
    },
    {
      id: 'createdAt',
      numeric: true,
      label: 'Date',
    },
    {
      id: 'user',
      numeric: true,
      label: 'Customer',
    },
    {
      id: 'address',
      numeric: true,
      label: 'Address',
    },
    {
      id: 'state',
      numeric: true,
      label: 'Status',
    },
    {
      id: 'sections',
      numeric: true,
      center: true,
      label: 'Sections',
    },
    {
      id: 'paymentMethod',
      numeric: true,
      label: 'Payment Method',
    },
    {
      id: 'debit',
      numeric: true,
      label: 'Debit',
    },
    {
      id: 'orderTotal',
      numeric: true,
      label: 'Total',
    },
    {
      id: '',
      numeric: false,
      label: '',
    },
];

export const orderProductTableHead = [
  {
    id: '_id',
    numeric: true,
    label: 'Product',
  },
  {
    id: 'scrapingItemTitle',
    numeric: true,
    label: 'Brand',
  },
  {
    id: 'price',
    numeric: true,
    label: 'Price',
  },
  {
    id: 'quantity',
    numeric: true,
    label: 'Quantity',
  },
  {
    id: 'totalPrice',
    numeric: true,
    label: 'Total',
  },
  {
    id: '',
    numeric: false,
    label: '',
  },
];

export const addProductsTableHead = [
  {
    id: '_id',
    numeric: true,
    label: 'Product',
  },
  {
    id: 'inStock',
    numeric: true,
    label: 'In Stock',
  },
  {
    id: 'price',
    numeric: true,
    label: 'Price',
  },
  {
    id: 'quantity',
    numeric: true,
    label: 'Quantity',
  },
  {
    id: 'total',
    numeric: true,
    label: 'Total',
  },
  {
    id: 'discount',
    numeric: true,
    label: 'Discount',
  },
  {
    id: '',
    numeric: false,
    label: '',
  }
];

export const initialShipping = { shippingType: "Price" || "Percent", shipping: Number() };
export type InitialShipping = typeof initialShipping;

export const initialFee = { feeType: "Price" || "Percent", fee: Number(), feeOptions: [{ limit: Number(), fee: Number() }] };
export type InitialFee = typeof initialFee;

export const initialExchange = { usdRate: Number(), tlRate: Number() || null };
export type InitialExchange = typeof initialExchange;

export const initialScrapingItem = {
  _id: String() || null,
  title: { main: String(), en: String() || null, kr: String() || null},
  exchange: initialExchange,
  fee: initialFee,
  shipping: initialShipping,
  fastShipping: initialShipping,
}
export type InitialScrapingItem = typeof initialScrapingItem;

export const initialCartItem = {
  _id: String() || null,
  title: String() || null,
  scrapingItem: initialScrapingItem,
  url: String() || null,
  sku: String() || null,
  image: String() || null,
  color: String() || null,
  size: String() || null,
  price: Number() || null,
  productPrice: Number() || null,
  quantity: Number() || null,
  colorName: String() || null,
  colorImage: String() || null,
};
export type initialCartItemType = typeof initialCartItem;

export const initialCartItems = [initialCartItem];
export type initialCartItemsType = typeof initialCartItems;

export const initialDeliveryGroup = {
  deliveryFee: Number(),
  dcFee: Number(),
  provinces: [String()],
  extraFeeCities: [String()],
  extraFee: Number() || null
}
export type InitialDeliveryGroup = typeof initialDeliveryGroup;

export const initialInsuranceGroup = {
  insuranceType: "Price" || "Percent",
  insurance: Number(),
}
export type InitialInsuranceGroup = typeof initialInsuranceGroup;

export const initialTaxGroup = {
  taxType: "Price" || "Percent",
  tax: Number(),
}
export type InitialTaxGroup = typeof initialTaxGroup;

export const initialcartOffer = {
  type: "Price" || "Percent",
  discountTarget: "Shipping & Delivery" || "Shipping" || "Delivery",
  discount: Number(),
  limit: Number(),
}
export type InitialcartOffer = typeof initialcartOffer;

export const initialcartOffers = [initialcartOffer];
export type InitialcartOffers = typeof initialcartOffers;


export const finalProductsData = [{ 
    scrapingItem: String() || null,
    image: File,
    title: String() || null,
    url: String() || null,
    sku: String() || null,
    color: String() || null,
    size: String() || null,
    price: Number() || null,
    quantity: Number() || null,
    colorName: String() || null,
    colorImage: String() || null,
}];

export type finalProductsData = typeof finalProductsData;


export const initialPayemntDiscount = {
  discount: Number(),
  type: String(),
  discountTarget: String()
}

export type initialPayemntDiscount = typeof initialPayemntDiscount;