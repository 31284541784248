
export const initialErrors = { image: String() || null, title: String() || null };
export type initialSectionErrorsType = typeof initialErrors;

export const initialSection = {
  title: {
      main: "",
      ar: String() || null,
      en: String() || null,
      fr: String() || null,
      tr: String() || null,
      kr: String() || null
  }
};

export const initialUpdateSection = {
  id: "",
  title: {
      main: "",
      ar: String() || null,
      en: String() || null,
      fr: String() || null,
      tr: String() || null,
      kr: String() || null
  }
};

export const storeSectionStatusList = [
  {label: "Active", value: "Active"},
  {label: "Disabled", value: "Disabled"}
];

export const storeSectionsTableHead = [
    {
      id: 'title',
      numeric: true,
      label: 'Title',
    },
    {
      id: 'target',
      numeric: true,
      label: 'Target',
    },
    {
      id: 'position',
      numeric: true,
      label: 'Position',
    },
    {
      id: 'state',
      numeric: true,
      label: 'Status',
    },
    {
      id: 'createdAt',
      numeric: true,
      label: 'CreatedAt',
    },
    {
      id: '',
      numeric: false,
      label: '',
    },
  ];