import { initialCartOfferErrors } from "../Constants/Cart-offers.constants";
import { Types } from "../Constants/Types";
import CartOffersType from "../Interfaces/cart-offers.interface";

const CartOffersReducer = (state: CartOffersType['status'] = { 
        cartOffer: undefined,
        cartOffers: [],
        cartOffersOrder: [],
        lcartOffers: false,
        lcartOffer: false,
        pages: 0,
        error: initialCartOfferErrors
    },
    action: CartOffersType['action']
    ) => {

    switch (action.type) {

        case Types.FETCH_CART_OFFERS:
            return {...state, cartOffers: action.payload, pages: action.pages};

        case Types.FETCH_CART_OFFERS_ORDER:
            return {...state, cartOffersOrder: action.payload};

        case Types.FETCH_CART_OFFER:
            return {...state, cartOffer: action.payload};

        case Types.CREATE_CART_OFFER:
            return {...state, cartOffers: [action.payload, ...state.cartOffers]};

        case Types.UPDATE_CART_OFFER: 
            return {...state, cartOffers: state.cartOffers.map(cartOffer => cartOffer._id === action.payload.id ? action.payload : cartOffer)};
        
        case Types.STATE_CART_OFFER: 
            return {...state, cartOffers: state.cartOffers.map(cartOffer => cartOffer._id === action.payload.id ? {...cartOffer, ...action.payload} : cartOffer), cartOffer: {...state.cartOffer, state: action.payload.state}};

        case Types.DELETE_CART_OFFER: 
            return {...state, cartOffers: state.cartOffers.filter(cartOffer => cartOffer._id !== action.payload)}

        case Types.START_CART_OFFERS:
            return {...state, lcartOffers: true};

        case Types.END_CART_OFFERS:
            return {...state, lcartOffers: false};

        case Types.ERROR_CART_OFFER:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_CART_OFFER:
            return {...state, error: initialCartOfferErrors}

        default: 
            return state
    }
}

export default CartOffersReducer;